import React from 'react'
import cx from 'classnames'

import MuiChip from '@mui/material/Chip'

import styles from './chip.module.scss'

interface ChipProps {
  label: any
  avatar?: React.ReactElement
  className?: string
  onDelete?: () => void
}

const Chip = ({ label, className = '', avatar, onDelete }: ChipProps) => {
  return <MuiChip label={label} className={cx(styles.chip, className)} avatar={avatar} onDelete={onDelete} />
}

export default Chip
