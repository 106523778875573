export const handleStyngUpload = (
  event: React.ChangeEvent<HTMLInputElement>,
  setStyngError: any,
  setStyngFormatError: any,
  setStyng: any,
) => {
  if (event.target.files) {
    const file = event.target.files[0]

    if (file.type === 'audio/mpeg' || file.type === 'audio/vnd.dlna.adts') {
      setStyng(file)
      setStyngError(false)
    } else {
      setStyngFormatError(true)

      setTimeout(() => setStyngFormatError(false), 3000)
    }
  }
}
