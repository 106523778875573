import { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { Duration, DateTime } from 'luxon'
import { useMutation } from 'react-query'

import useStores from 'common/hook/useStore'

import PlaylistsStore, { useListPlaylistsQuery } from '../../PlaylistsStore'

import Table from 'ui/Table/Table'
import Spinner from 'ui/Spinner/Spinner'

import Text from 'locale/strings'
import AdditionTablePlaylistInfo from '../../components/PlaylistInfo/AdditionalTablePlaylistInfo'
import Pagination from 'ui/Pagination/Pagination'
import SectionFilters from 'ui/SectionFilters/SectionFilters'

import { truncateText } from 'common/utils'
import { FilterFields } from 'common/constants'
import API from 'common/api'
import Button from 'ui/Button/Button'
import { Playlist, DeletePlaylist, ActivatePlaylist, DeactivatePlaylist } from 'common/api/playlist/playlist'
import DeleteModal from 'ui/DeleteModal/DeleteModal'
import DmcaCheckModal from '../../components/DmcaCheckModal/DmcaCheckModal'
import { PlaylistsCreateRoute, PlaylistEditRoute } from '../../index'

import styles from './PlaylistsTable.module.scss'

interface PlaylistsTableProps {
  playlistsStore: PlaylistsStore
}

const PlaylistsTable = ({ playlistsStore }: PlaylistsTableProps) => {
  const { navigationStore, notificationsStore } = useStores()
  const { data, status, refetch } = useListPlaylistsQuery(playlistsStore)
  const playlists = data?.playlists ?? []
  const numberOfPages = data?.pagesCount ?? 10
  const [openDmcaModal, setOpenDmcaModal] = useState<boolean>(false)
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [selectedPlaylistId, setSelectedPlaylistId] = useState<string>('')
  const [selectedPlaylistName, setSelectedPlaylistName] = useState<string>('')
  const [selectedNumOfTracks, setSelectedNumOfTracks] = useState<string>('')

  const columns = [
    'Id',
    'Name',
    'Duration',
    'Num of tracks',
    'App',
    'Dmca',
    'Type',
    'Date and time published',
    'Published',
    'Actions',
  ]

  const dataTable = playlists.map((playlist: Playlist) => [
    playlist.id,
    truncateText(playlist.name),
    Duration.fromISO(playlist.duration).toFormat('hh:mm:ss'),
    playlist.numberOfTracks.toString(),
    truncateText(playlist.appName),
    playlist.dmcaCompliant ? 'true' : 'false',
    playlist.type,
    playlist.publishedAt === null ? '/' : DateTime.fromISO(playlist.publishedAt).toFormat('ff'),
    playlist.publishedAt === null ? 'false' : 'true',
  ])

  useEffect(() => {
    return () => {
      playlistsStore.changePage(1)
    }
  }, [])

  const dataTableAdditionalInfo = playlists.map((playlist: Playlist) => [
    {
      id: playlist.id,
      name: playlist.name,
    },
  ])

  const handleAddPlaylist = () => {
    navigationStore.goToPage(PlaylistsCreateRoute.path)
  }

  const handleDeleteClick = (id: string, name: string) => {
    setSelectedPlaylistId(id)
    setSelectedPlaylistName(name)
    setShowDeleteModal(true)
  }

  const handleCloseDeleteModal = () => {
    setSelectedPlaylistId('')
    setSelectedPlaylistName('')
    setShowDeleteModal(false)
  }

  const handleChangePage = (value: number) => {
    playlistsStore.changePage(value)
  }

  const onDeleteMutation = useMutation<null, Error, DeletePlaylist>(
    (id) => {
      return API.playlist.delete(id)
    },
    {
      onSuccess: () => {
        refetch()

        notificationsStore.successNotification('Playlist successfully removed')
        setShowDeleteModal(false)
      },
      onError: (error: any) => {
        notificationsStore.errorNotification(error)
      },
    },
  )

  const onActivateMutation = useMutation<null, Error, ActivatePlaylist>(
    (id) => {
      return API.playlist.activate(id)
    },
    {
      onSuccess: () => {
        refetch()

        notificationsStore.successNotification('Playlist successfully published')
      },
      onError: (error: any) => {
        notificationsStore.errorNotification(error)
      },
    },
  )

  const onDeactivateMutation = useMutation<null, Error, DeactivatePlaylist>(
    (id) => {
      return API.playlist.deactivate(id)
    },
    {
      onSuccess: () => {
        refetch()

        notificationsStore.successNotification('Playlist successfully unpublished')
      },
      onError: (error: any) => {
        notificationsStore.errorNotification(error)
      },
    },
  )

  const handleActivate = (id: string, active: string) => {
    if (active === 'false') {
      onActivateMutation.mutate({ id: id })
    } else {
      onDeactivateMutation.mutate({ id: id })
    }
  }

  const handleDeletePlaylist = () => {
    onDeleteMutation.mutate({ playlistId: selectedPlaylistId })
  }

  const handleOpenDmcaModal = (
    id?: string | undefined,
    name?: string | undefined,
    numOfTracks?: string | undefined,
  ) => {
    setSelectedNumOfTracks(numOfTracks ?? '')
    setSelectedPlaylistId(id ?? '')
    setSelectedPlaylistName(name ?? '')
    setOpenDmcaModal(true)
  }

  const handleCloseDmcaModal = () => {
    setSelectedNumOfTracks('')
    setSelectedPlaylistId('')
    setSelectedPlaylistName('')
    setOpenDmcaModal(false)
  }

  const handleShowMoreInfo = (id: string) => {
    const playlist = playlists.filter((item) => item.id === id)

    navigationStore.goToPage(
      PlaylistEditRoute.getUrl({
        playlistId: id,
        appId: playlist[0].appId,
      }),
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Button data-test="create-button" onClick={() => handleAddPlaylist()}>
          {Text.navigation.createPlaylist}
        </Button>
      </div>

      <SectionFilters
        sectionStore={playlistsStore}
        filters={[FilterFields.NAME, FilterFields.APPLICATION, FilterFields.PUBLISHED, FilterFields.TYPE]}
      />
      <div className={styles.tableWrapper}>
        {status === 'loading' ? (
          <Spinner />
        ) : (
          <Table
            dmcaCheckButton
            hideColData
            publishedToggle
            disabledPlaylistToggle
            hiddenColumns={['Id', 'Dmca', 'Type']}
            dataAdditionalInfo={dataTableAdditionalInfo}
            additionalDataComponent={(props) => <AdditionTablePlaylistInfo {...props} />}
            columns={columns}
            data={dataTable}
            handleActivate={handleActivate}
            onEditClick={handleShowMoreInfo}
            onDeleteClick={handleDeleteClick}
            onDmcaCheckClick={handleOpenDmcaModal}
          />
        )}
        {playlists.length > 0 && (
          <div>
            <div className={styles.paginationContainer}>
              <div className={styles.pagination}>
                <Pagination page={playlistsStore.page} count={numberOfPages} onChange={handleChangePage} />
              </div>
            </div>
          </div>
        )}

        {openDmcaModal && (
          <DmcaCheckModal
            open={openDmcaModal}
            playlistId={selectedPlaylistId}
            playlistName={selectedPlaylistName}
            numOfTracks={selectedNumOfTracks}
            handleClose={handleCloseDmcaModal}
          />
        )}

        {showDeleteModal && (
          <DeleteModal
            open={showDeleteModal}
            headerContent={'Delete playlist'}
            text={`Do you want to delete ${truncateText(selectedPlaylistName)} playlist ?`}
            handleClose={handleCloseDeleteModal}
            handleSubmit={handleDeletePlaylist}
          />
        )}
      </div>
    </div>
  )
}

export default observer(PlaylistsTable)
