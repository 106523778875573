import { observer } from 'mobx-react'

import CompaniesStore from '../CompaniesStore'
import CompaniesTable from '../pages/CompaniesTable/CompaniesTable'

const Companies = () => {
  const companiesStore = new CompaniesStore()

  return <CompaniesTable companiesStore={companiesStore} />
}

export default observer(Companies)
