import * as R from 'ramda'

import Button from '../../ui/Button/Button'
import Text from '../../locale/strings'
import useStores from '../../common/hook/useStore'
import { AppEvent } from '../../common/api/appEvent/appEvent'
import { CreateAppEventRoute, EditAppEventRoute } from '../../appEvent/pages'
import Table from '../../ui/Table/Table'

import styles from './appEvents.module.scss'

interface AppEventsProps {
  events: AppEvent[]
}

const AppEvents = ({ events }: AppEventsProps) => {
  const { navigationStore } = useStores()

  const handleNewAppEvent = () => {
    navigationStore.goToPage(CreateAppEventRoute.path)
  }

  const handleClickAppEvent = (id: string) => {
    if (!R.isEmpty(id)) {
      navigationStore.goToPage(EditAppEventRoute.getUrl({ appEventId: id }))
    }
  }

  const columns = ['Id', 'Name', 'Description']

  const dataTable = events.map((event: AppEvent) => [event.id, event.name, event.description])

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Button onClick={handleNewAppEvent}>{Text.navigation.createAppEvent}</Button>
      </div>
      <Table columns={columns} data={dataTable} onClickRow={handleClickAppEvent} />
    </div>
  )
}

export default AppEvents
