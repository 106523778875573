import useStores from '../common/hook/useStore'
import Text from '../locale/strings'

import styles from './mainPage.module.scss'

const MainPage = () => {
  const { userStore } = useStores()

  return (
    <div className={styles.container}>
      <div className={styles.logo} />
      <h1 className={styles.title}>
        {Text.page.mainPage.casualGreetingsHi}, {userStore.getUserCredentials()}
      </h1>
      <h2>{Text.page.mainPage.welcomeMessage}</h2>
    </div>
  )
}

export default MainPage
