import { useMutation } from 'react-query'

import API from 'common/api'
import useStores from 'common/hook/useStore'
import { GetStyngRequest } from 'common/api/styng/styng'
import { ApiError } from 'lib/api/error'

export const useGetStyng = (onSuccess: any) => {
  const { notificationsStore } = useStores()

  return useMutation<string, ApiError, GetStyngRequest>(
    async (body: GetStyngRequest) => {
      return API.track.getStyngUrl(body)
    },
    {
      onSuccess: (data) => {
        if (onSuccess) {
          onSuccess(data)
        }
      },
      onError: () => {
        notificationsStore.errorNotification('Styng is unavailable for reproduction')
      },
    },
  )
}
