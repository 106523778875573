import React from 'react'

import MuiPagination from '@mui/material/Pagination'

interface PaginationProps {
  page: number
  count: number
  onChange: (value: number) => void
}

const Pagination = ({
  page,
  count,
  onChange,
}: PaginationProps) => {

  const handleChangePage = (event: React.ChangeEvent<unknown>, newPage: number) => {
    onChange(newPage)
  }

  return (
    <MuiPagination
      count={count}
      page={page}
      onChange={handleChangePage}
    />
  )
}

export default Pagination
