/* eslint-disable jsx-control-statements/jsx-for-require-each */
import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import Storage from '../../common/storage'

import MuiDrawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItemText from '@mui/material/ListItemText'
import ListItemButton from '@mui/material/ListItemButton'

import useStores from '../../common/hook/useStore'
import Route from 'lib/routing/Route'
import { UsersRoute } from '../../users'
import { PlaylistsRoute } from '../../playlists'
import { PlatformsRoute } from '../../platforms'
import { CompaniesRoute } from '../../companies'
import { StyngsRoute } from '../../styngs'
import { StynglistsRoute } from '../../stynglists'
import { SoundboardRoute } from '../../soundboard'
import { AdminMode, Permission } from '../../common/constants'
import { AppsRoute } from '../../apps/pages'
import { NftsRoute } from '../../nfts/pages'
import { TracksForRadioRoute } from '../../tracks/pages'
import { TracksForStyngsRoute } from '../../tracksForStyngs/pages'
import { ReportsRoute } from '../../reporting'
import { ActivityLogRoute } from '../../activityLog'

import styles from './drawer.module.scss'

interface PermissionRoute extends Route {
  permission?: Permission
}

const listNavigation = (adminMode: AdminMode): PermissionRoute[] => {
  return [
    UsersRoute,
    PlatformsRoute,
    CompaniesRoute,
    AppsRoute,
    PlaylistsRoute,
    StyngsRoute,
    StynglistsRoute,
    SoundboardRoute,
    NftsRoute,
    TracksForRadioRoute,
    TracksForStyngsRoute,
    ReportsRoute,
    ActivityLogRoute,
  ]
}

const Drawer = () => {
  const navigation = [
    '/users',
    '/platforms',
    '/companies',
    '/apps',
    '/playlists',
    '/styngs',
    '/stynglists',
    '/soundboard',
    '/nfts',
    '/tracks',
    '/tracks-for-styngs',
    '/reporting',
    '/activity-log',
  ]

  const { applicationStore, navigationStore } = useStores()

  const [selectedPage, setSelectedPage] = React.useState<string>(navigationStore.currentPath)

  const handleNavigate = (path: string) => {
    return () => {
      navigationStore.goToPage(path)
      setSelectedPage(path)
    }
  }

  useEffect(() => {
    window.addEventListener(
      'popstate',
      (event) => {
        if (event.state) {
          setSelectedPage(navigationStore.currentPath)
        }
      },
      false,
    )

    return () => {
      window.removeEventListener('popstate', () => {})
    }
  }, [])

  useEffect(() => {
    if (navigationStore.currentPath.includes(selectedPage)) {
      navigation.forEach((e) => {
        if (selectedPage.includes(e)) {
          return setSelectedPage(e)
        }
      })
    }
  }, [navigationStore.currentPath])

  return (
    <MuiDrawer className={styles.drawer} variant="permanent">
      <List>
        {listNavigation(applicationStore.adminMode).map((route: PermissionRoute, index: number) => {
          return (
            <ListItemButton
              data-test="sidebar-button"
              selected={route.path === selectedPage}
              key={route.path}
              onClick={handleNavigate(route.path)}
            >
              <ListItemText primary={route.name === 'Company' ? 'Companies' : route.name} />
            </ListItemButton>
          )
        })}
      </List>

      <div className={styles.versionWrapper}>
        <div>FE version: {process.env.REACT_APP_VERSION}</div>
        <div>BE version: {Storage.getBeVersion('version')}</div>
      </div>
    </MuiDrawer>
  )
}

export default observer(Drawer)
