import { observer } from 'mobx-react'
import { thread } from 'lib/fn/thread'

import PlatformsStore from '../PlatformsStore'

import PlatformsTable from './PlatformsTable/PlatformsTable'

const Platform = () => {
  const platformsStore = new PlatformsStore()

  return <PlatformsTable platformsStore={platformsStore} />
}

export default thread(Platform, [observer])
