import { action, observable } from 'mobx'
import { useQuery } from 'react-query'
import * as R from 'ramda'

import API from '../common/api'
import useStores from '../common/hook/useStore'
import PaginationStore from '../common/PaginationStore'

class AdGroupsStore extends PaginationStore {
  @observable
  adGroupId: string = ''
  @observable
  id: string = ''
  @observable
  playlist: string = ''

  @action
  handleIdSearch = (filter: string) => {
    this.id = filter?.trim()
    this.changePage()
  }

  @action
  handlePlaylistSearch = (filter: string) => {
    this.playlist = filter?.trim()
    this.changePage()
  }
}

export const useAdGroupQuery = (adGroupParam: Nullable<string> = null) => {
  const { adGroupsStore } = useStores()
  const { adGroupId: adGroupStore } = adGroupsStore
  const playlistId = adGroupParam ?? adGroupStore

  return useQuery<any>(
    ['adGroup', playlistId],
    () => {
      return API.playlist.getAdGroup({
        playlistId: playlistId,
      })
    },
    {
      enabled: !R.isEmpty(playlistId),
      keepPreviousData: true,
      retry: 1,
    },
  )
}

export default AdGroupsStore
