import { QueryStatus } from 'react-query'

import LoadingComponent from '../ui/Loading/LoadingPage'
import ErrorLoading from '../ui/ErrorLoading/ErrorLoading'

interface LoadingRemoteProps {
  status: QueryStatus
  children: React.ReactNode
}

const LoadingRemote = ({ status, children }: LoadingRemoteProps) => {
  return (
    <Choose>
      <When condition={status === 'loading'}>
        <LoadingComponent />
      </When>
      <When condition={status === 'success'}>{children}</When>
      <When condition={status === 'error'}>
        <ErrorLoading />
      </When>
    </Choose>
  )
}

export default LoadingRemote
