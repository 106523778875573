import { useQuery } from 'react-query'

import API from '../common/api'
import useStores from '../common/hook/useStore'
import { ReportsResponse, ReportTypesResponse } from '../common/api/reporting/reports'

export const useReportsQuery = () => {
  const { reportsStore } = useStores()

  const { pageSize, page } = reportsStore

  return useQuery<ReportsResponse>(
    ['reports', pageSize, page],
    () => {
      return API.reports.list({
        size: pageSize,
        page: page,
      })
    },
    {
      retry: 1,
      keepPreviousData: true,
    },
  )
}

export const useReportTypesQuery = () => {
  return useQuery<ReportTypesResponse>(
    ['reportTypes'],
    () => {
      return API.reports.getReportTypes()
    },
    {
      retry: 1,
      keepPreviousData: true,
    },
  )
}
