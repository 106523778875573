import { observer } from 'mobx-react'
import { thread } from 'lib/fn/thread'

import SoundboardStore from '../SoundboardStore'

import SoundboardTable from './SoundboardTable/SoundboardTable'

const Soundboard = () => {
  const soundboardStore = new SoundboardStore()

  return <SoundboardTable soundboardStore={soundboardStore} />
}

export default thread(Soundboard, [observer])
