import { observer } from 'mobx-react'
import { useParams } from 'react-router-dom'
import * as R from 'ramda'

import { thread } from 'lib/fn/thread'
import PermissionValidationHOC from '../../user/PermissionValidationHOC'
import { Permission } from '../../common/constants'
import useStores from '../../common/hook/useStore'
import LoadingRemote from '../../common/LoadingRemote'
import { useAppEventQuery } from '../../appEvent/hooks'
import EditAppEvent from '../../appEvent/EditAppEvent/EditAppEvent'
import { AppEventsRoute } from '.'

interface ParamProps {
  appEventId?: string
}

const EditAppEventPage = () => {
  const { navigationStore } = useStores()
  const { appEventId = '' } = useParams<ParamProps>()
  const { data, status } = useAppEventQuery(appEventId)

  if (R.isEmpty(appEventId)) {
    navigationStore.goToPage(AppEventsRoute.path)
  }

  return (
    <LoadingRemote status={status}>
      <EditAppEvent initialAppEvent={data} />
    </LoadingRemote>
  )
}

export default thread(EditAppEventPage, [observer, PermissionValidationHOC(Permission.CREATE_APP_EVENT)])
