/* eslint-disable jsx-control-statements/jsx-for-require-each */

import { observer } from 'mobx-react'

import Text from '../../locale/strings'
import { TypedIdentity } from '../../common/api/common/common'
import Chip from '../../ui/Chip/Chip'
import TracksForStyngsStore from '../../tracksForStyngs/TracksForStyngsStore'

import styles from './trackFilterChips.module.scss'

interface TrackFilterChipsProps {
  tracksStore: TracksForStyngsStore
}

const TrackFilterChips = ({ tracksStore }: TrackFilterChipsProps) => {
  return (
    <div className={styles.container}>
      <Choose>
        <When condition={tracksStore.labels.length === 0}>
          <h6 className={styles.noLabels}>{Text.common.noFilters}</h6>
        </When>
        <Otherwise>
          <For
            of={tracksStore.labels}
            body={(label: TypedIdentity) => (
              <Chip
                key={`${label.typeIdentity}-${label.id}`}
                label={label.name}
                className={styles.chip}
                avatar={<h6 className={styles.avatar}>{label.typeIdentity}</h6>}
                onDelete={tracksStore.handleDeleteFilter(label.typeIdentity, label)}
              />
            )}
          />
        </Otherwise>
      </Choose>
    </div>
  )
}

export default observer(TrackFilterChips)
