import strings from '../../locale/strings'
import Route, { ParametrizedRoute } from 'lib/routing/Route'
import EditAppEventPage from './EditAppEventPage'
import CreateAppEventPage from './CreateAppEventPage'
import AppEventsPage from './AppEventsPage'

export const AppEventsRoute: Route = {
  path: '/app-events',
  name: strings.navigation.appEvents,
  exact: true,
  component: AppEventsPage,
}

export const CreateAppEventRoute: Route = {
  path: '/app-events/create',
  name: strings.navigation.createAppEvent,
  exact: true,
  component: CreateAppEventPage,
}

export const EditAppEventRoute: ParametrizedRoute = {
  path: '/app-events/edit/:appEventId',
  name: strings.navigation.createAppEvent,
  exact: true,
  component: EditAppEventPage,
  getUrl: ({ appEventId }) => `/app-events/edit/${appEventId}`,
}
