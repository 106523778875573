import { useQuery } from 'react-query'
import * as R from 'ramda'

import { AppEventsResponse, EditAppEventEntity } from '../common/api/appEvent/appEvent'
import useStores from '../common/hook/useStore'
import API from '../common/api'

export const useAppEventsQuery = () => {
  const { applicationStore } = useStores()
  const { appId } = applicationStore

  return useQuery<AppEventsResponse>(
    ['events', appId],
    () => {
      return API.event.list({
        appId,
      })
    },
    {
      enabled: !R.isEmpty(appId),
      retry: 1,
      keepPreviousData: true,
    },
  )
}

export const useAppEventQuery = (id: string) => {
  const { applicationStore } = useStores()
  const { appId } = applicationStore

  return useQuery<EditAppEventEntity>(
    ['event', id, appId],
    () => {
      return API.event.get({
        id,
        appId,
      })
    },
    {
      enabled: !R.isEmpty(id) && !R.isEmpty(appId),
      keepPreviousData: true,
      retry: 1,
    },
  )
}
