import React from 'react'
import cx from 'classnames'

import LoadingButton from '@mui/lab/LoadingButton'

import styles from './button.module.scss'
// import { boolean } from 'yup'

export enum ButtonSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export enum ButtonColor {
  PRIMARY = 'primary',
  ERROR = 'error',
  SECONDARY = 'secondary',
  WARNING = 'warning',
  INFO = 'info',
  INHERIT = 'inherit',
}

export enum ButtonVariant {
  CONTAINED = 'contained',
  OUTLINED = 'outlined',
  TEXT = 'text',
}

interface ButtonProps {
  children: React.ReactElement[] | React.ReactElement | string
  type?: 'button' | 'submit'
  loading?: boolean
  className?: string
  color?: ButtonColor
  variant?: ButtonVariant
  isUpload?: boolean
  size?: ButtonSize
  disabled?: boolean
  sx?: {}
  onClick?: () => unknown
}

const Button: React.FC<ButtonProps> = ({
  type = 'button',
  children,
  sx,
  variant = ButtonVariant.CONTAINED,
  size = ButtonSize.MEDIUM,
  color = ButtonColor.PRIMARY,
  loading = false,
  className,
  isUpload = false,
  disabled = false,
  ...props
}) => {
  return (
    <LoadingButton
      className={cx(styles.button, className)}
      sx={sx}
      type={type}
      variant={variant}
      disabled={disabled}
      size={size}
      color={color}
      loading={loading}
      component={isUpload ? 'span' : 'button'}
      loadingPosition="center"
      {...props}
    >
      {children}
    </LoadingButton>
  )
}

export default Button
