import { useQuery } from 'react-query'
import * as R from 'ramda'

import { Currency, EditCurrencyEntity } from '../common/api/currency/currency'
import useStores from '../common/hook/useStore'
import API from '../common/api'

export const useCurrencyQuery = (currencyId: number) => {
  return useQuery<EditCurrencyEntity>(
    ['currency', currencyId],
    () => {
      return API.currency.get({
        currencyId,
      })
    },
    {
      enabled: currencyId !== 0,
      keepPreviousData: true,
      retry: 1,
    },
  )
}

export const useCurrenciesQuery = (appParam: Nullable<string> = null) => {
  const { applicationStore } = useStores()
  const { appId: appStore } = applicationStore
  const appId = appParam ?? appStore

  return useQuery<Currency[]>(
    ['currencies', appId],
    () => {
      return API.currency.list({
        appId,
      })
    },
    {
      enabled: !R.isEmpty(appId),
      keepPreviousData: true,
      retry: 1,
    },
  )
}
