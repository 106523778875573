import Route from 'lib/routing/Route'
import TracksForStyngsPage from './TracksForStyngsPage'
import strings from '../../locale/strings'

export const TracksForStyngsRoute: Route = {
  path: '/tracks-for-styngs',
  name: strings.navigation.tracksForStyngs,
  exact: true,
  component: TracksForStyngsPage,
}
