import * as R from 'ramda'
import cx from 'classnames'

import styles from './errorText.module.scss'

interface ErrorTextProps {
  error: Nullable<string>
  className?: string
}

const ErrorText = ({
  error,
  className = '',
}: ErrorTextProps ) => {
  if (R.isNil(error) || R.isEmpty(error)) {
    return null
  }

  return (
    <div className={cx(styles.container, className)}>
      {error}
    </div>
  )
}

export default ErrorText
