import IconButton from '@mui/material/IconButton'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Visibility from '@mui/icons-material/Visibility'
import InputAdornment from '@mui/material/InputAdornment'

interface PasswordEyeProps {
  visible: boolean
  onClick: () => void
}

const PasswordEye = ({ visible, onClick }: PasswordEyeProps) => {
  return (
    <InputAdornment position="end">
      <IconButton aria-label="toggle password visibility" edge="end" onClick={onClick}>
        {visible && <VisibilityOff />}
        {!visible && <Visibility />}
      </IconButton>
    </InputAdornment>
  )
}

export default PasswordEye
