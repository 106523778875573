import { observer } from 'mobx-react'
import { thread } from 'lib/fn/thread'

import UserStore from '../UsersStore'

import PermissionValidationHOC from '../../user/PermissionValidationHOC'
import { Permission } from '../../common/constants'
import UsersTable from '../pages/UsersTable/UsersTable'

const UsersPage = () => {
  const usersStore = new UserStore()

  return <UsersTable usersStore={usersStore} />
}

export default thread(UsersPage, [observer, PermissionValidationHOC(Permission.USERS_VIEW)])
