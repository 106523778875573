import { Identity } from 'common/api/common/common'

export interface Label extends Identity {
  mediaNetId: number
}

type LabelEntity = Exclude<Label, 'id'>

export interface LabelsResponse {
  labels: LabelEntity[],
}

export const toLabel = (entity: LabelEntity): Label => ({
  ...entity,
  id: entity.mediaNetId,
})
