import API from '../../common/api'
import { ImageUrl } from '../../common/api/image/image'
import { useQuery } from 'react-query'
import * as R from 'ramda'

const useImage = (id: string) => {
  return useQuery<Nullable<ImageUrl>>(
    ['styng', id],
    () => {
      if (R.isNil(id) || id === '') {
        return null
      }

      return (
        API.image.get({
          id,
        }) ?? []
      )
    },
    {
      keepPreviousData: true,
      retry: 3,
      refetchOnWindowFocus: false,
    },
  )
}

export default useImage
