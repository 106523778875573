import React from 'react'
import { Typography } from '@mui/material'

interface ITextProps{
  children: string | string[],
  className?: string
}

const Text: React.FC<ITextProps> = ({ children, className = '' }: ITextProps) => {
  return (
    <Typography
      className={className}
    >
      {children}
    </Typography>
  )
}

export default Text
