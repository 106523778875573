import React from 'react'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import { observer } from 'mobx-react'
import useStores from '../common/hook/useStore'

const Notifications = () => {
  const { notificationsStore } = useStores()
  const notifications = notificationsStore.notifications

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
    return <MuiAlert variant="filled" elevation={6} ref={ref} {...props} />
  })

  return (
    <React.Fragment>
      {notifications.map((notification, index) => (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={notifications.length > 0}
          autoHideDuration={notification.autoHideDuration}
          key={index}
          onClose={() => notificationsStore.removeNotification(0)}
        >
          <Alert key={index} severity={notification.type}>
            {notification.message}
          </Alert>
        </Snackbar>
      ))}
    </React.Fragment>
  )
}

export default observer(Notifications)
