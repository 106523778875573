import EventEmitter from 'events'

export interface ISessionEvent extends Event {
  type: string
  key: string
  newValue: string
  version: string
}

class Storage extends EventEmitter {
  static eventType = 'storage'

  constructor() {
    super()

    window.addEventListener(Storage.eventType, this.onSessionIdChange as EventListener)
  }

  getBeVersion(version: string): Nullable<string> {
    return window.localStorage.getItem(version)
  }

  setBeVersion(version: string, value: string) {
    window.localStorage.setItem(version, value)
  }

  get(key: string): Nullable<string> {
    return window.localStorage.getItem(key)
  }

  set(key: string, value: string) {
    window.localStorage.setItem(key, value)
  }

  delete(key: string) {
    window.localStorage.removeItem(key)
  }

  private onSessionIdChange = ({ type, key, newValue }: ISessionEvent) => {
    if (type === Storage.eventType) {
      this.emit('change', key, newValue)
    }
  }

  close() {
    window.removeEventListener(Storage.eventType, this.onSessionIdChange as EventListener)
  }
}

export default new Storage()
