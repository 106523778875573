import React, { useEffect, useState } from 'react'
// import * as R from 'ramda'
import cx from 'classnames'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import { thread } from 'lib/fn/thread'

import UserProfileHOC from '../../user/UserProfileHOC'
import AuthHOC from '../../auth/AuthHOC'
import useStores from '../../common/hook/useStore'
import { LoginRoute } from '../../auth/pages/Login'
import Header from '../../ui/Header/Header'
import Drawer from '../../ui/Drawer/Drawer'
import { AdminMode } from '../../common/constants'
import Storage from '../../common/storage'

import styles from './main.module.scss'

const MainLayout: React.FC = ({ children }) => {
  const history = useHistory()
  const { authStore, userStore, applicationStore } = useStores()

  const { companyId, appId, setAppId } = applicationStore

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [openNavigation, setOpenNavigation] = useState(true)

  useEffect(() => {
    const defaultApp = userStore.getCompanyApp(companyId)[0]?.id ?? ''

    setAppId(defaultApp)
  }, [companyId])

  const handleLogout = async () => {
    await authStore.logout()
    await applicationStore.setAdminMode(AdminMode.OFF)
    Storage.delete('adminMode')

    history.push(LoginRoute.path)
  }

  const contentClassName = cx(styles.content, {
    [styles.open]: openNavigation,
  })

  return (
    <div className={styles.container}>
      <Header
        applications={userStore.getCompanyApp(companyId)}
        companies={userStore.getCompanies()}
        companyName={companyId}
        applicationName={appId}
        profileCredentials={userStore.getUserCredentials()}
        openNavigation={openNavigation}
        onLogout={handleLogout}
        onChangeApp={setAppId}
      />
      <Drawer />
      <div id="mainLayoutContainer" className={contentClassName}>
        {children}
      </div>
    </div>
  )
}

export default thread(MainLayout, [observer, UserProfileHOC, AuthHOC])
