import { useState } from 'react'
import { useMutation } from 'react-query'
import { observer } from 'mobx-react'

import useStores from '../../../common/hook/useStore'

import ApplicationStore, { useAppsQuery } from '../../ApplicationStore'

import SectionFilters from 'ui/SectionFilters/SectionFilters'
import Button from '../../../ui/Button/Button'
import Text from '../../../locale/strings'
import Table from '../../../ui/Table/Table'
import Pagination from 'ui/Pagination/Pagination'
import Spinner from 'ui/Spinner/Spinner'

import API from '../../../common/api'
import { Application } from '../../../common/api/app/app'
import { truncateText } from 'common/utils'
import { FilterFields } from 'common/constants'
import Link from '@mui/material/Link'

import AdditionTableAppInfo from '../../AppInfo/AdditionalTableAppInfo'
import DeleteModal from '../../../ui/DeleteModal/DeleteModal'
import { AppInfoRoute, AppGeneralFormNewRoute, AppGeneralFormEditRoute } from '..'

import styles from './AppsTable.module.scss'

interface AppsProps {
  applicationStore: ApplicationStore
}

const AppsTable = ({ applicationStore }: AppsProps) => {
  const { navigationStore, notificationsStore } = useStores()
  const { data, status, refetch } = useAppsQuery(applicationStore)
  const apps = data?.apps ?? []
  const numberOfPages = data?.pageCount ?? 10
  const [appName, setAppName] = useState<string>('')
  const [selectedAppId, setSelectedAppId] = useState<string>('')
  // Delete modal
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)

  const handleShowMoreInfo = (id: string, name: string) => {
    navigationStore.goToPage(AppInfoRoute.getUrl({ appId: id }))
  }

  const columns = ['Id', 'Name', 'Actions']

  const dataTable = apps.map((app: Application) => [
    app.id,
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {truncateText(app.name)}
      <div style={{ cursor: 'pointer' }}>
        {'('}
        <Link onClick={() => handleShowMoreInfo(app.id, app.name)}>Show more</Link>
        {')'}
      </div>
    </div>,
  ])

  const dataTableAdditionalInfo = apps.map((app: Application) => [
    {
      id: app.id,
      name: app.name,
      paymentSystemUrl: app.paymentSystemUrl!,
      publicKey: app.publicKey,
      secretKey: app.secretKey,
      company: app.company,
      price: app.price,
      apiKey: app.apiKey,
    },
  ])

  const handleCreateClick = () => {
    navigationStore.goToPage(AppGeneralFormNewRoute.path)
  }

  const handleEditClick = (id: string) => {
    navigationStore.goToPage(AppGeneralFormEditRoute.getUrl({ appId: id }))
  }

  //  Delete flow start
  const handleDeleteClick = (id: string, name: string) => {
    setSelectedAppId(id)
    setAppName(name)
    setShowDeleteModal(true)
  }

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false)
    setSelectedAppId('')
  }

  const handleDeleteApp = () => {
    deleteMutation.mutate()
  }

  const deleteMutation = useMutation(
    async () => {
      return API.app.delete({ appId: selectedAppId })
    },
    {
      onSuccess: () => {
        refetch()
        setShowDeleteModal(false)
        notificationsStore.successNotification('App successfully deleted')
      },
      onError: (error: any) => {
        setShowDeleteModal(false)

        notificationsStore.errorNotification(error)
      },
    },
  )
  //  Delete flow end

  const handleChangePage = (value: number) => {
    applicationStore.changePage(value)
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Button data-test="create-button" onClick={handleCreateClick}>
          {Text.navigation.createApp}
        </Button>
      </div>

      <SectionFilters sectionStore={applicationStore} filters={[FilterFields.NAME, FilterFields.ID]} />
      <div className={styles.tableWrapper}>
        {status === 'loading' ? (
          <Spinner />
        ) : (
          <Table
            hideDelete
            columns={columns}
            data={dataTable}
            dataAdditionalInfo={dataTableAdditionalInfo}
            additionalDataComponent={(props) => <AdditionTableAppInfo {...props} />}
            onEditClick={handleEditClick}
            onDeleteClick={handleDeleteClick}
          />
        )}

        {apps.length > 0 && (
          <div>
            <div className={styles.paginationContainer}>
              <div className={styles.pagination}>
                <Pagination page={applicationStore.page} count={numberOfPages} onChange={handleChangePage} />
              </div>
            </div>
          </div>
        )}
      </div>
      {showDeleteModal && (
        <DeleteModal
          open={showDeleteModal}
          headerContent={'Delete app'}
          handleClose={handleCloseDeleteModal}
          text={`Do you want to delete ${truncateText(appName)} app?`}
          handleSubmit={handleDeleteApp}
        />
      )}
    </div>
  )
}

export default observer(AppsTable)
