export enum ErrorCode {
  TRACK_WITHDRAWN = 112,
  LOGIN_NOT_VALID = 113,
  USER_WITH_SAME_EMAIL_ALREADY_REGISTERED = 115,
  COMPANY_WITH_SAME_NAME_ALREADY_EXIST = 116,
  TRACK_ALREADY_EXISTS_IN_PLAYLIST = 126,
  INVALID_PLAYLIST_FILE_FORMAT = 148,
  PROVIDED_INPUT_CAN_NOT_BE_MAPPED_TO_TARGET_DEFINITION = 166,
  AD_WITH_PROVIDED_ID_DOES_NOT_EXIST = 167,
  PLAYLIST_NAME_ALREADY_EXISTS = 170,
  TRACK_NOT_AVAILABLE = 174,
  AT_LEAST_ONE_REPEATABLE_AD_BLOCK = 179,
  MAX_STREAM_MINUTES_EXCEEDED = 180,
  REQUIRED_STREAM_MINUTES_NOT_PRVOIDED = 181,
  REQUIRED_STREAM_COUNT_NOT_PRVOIDED = 182,
  APPLICATION_WITH_SAME_NAME_ALREADY_REGISTERED_WITHIN_COMPANY = 192,
  SOUNDBOARD_WITH_SAME_NAME_ALREADY_REGISTERED = 194,
  SOUNDBOARD_STYNG_ALREADY_ADDED_TO_CATEGORY = 198,
  SOUNDBOARD_WITH_SAME_TYPE_IS_ALREADY_PUBLISHED_FOR_SELECTED_APP = 200,
  PLATFORM_DUPLICATE_NAME_PROVIDED = 206,
  PLATFORM_DUPLICATE_OFFER_CODE_PREFIX_PROVIDED = 208,
  STYNG_WITH_SAME_NAME_ALREADY_EXIST = 210,
}
