import React from 'react'
import MuiTooltip from '@mui/material/Tooltip'

interface TooltipProps {
  title: string
  children: React.ReactElement
  open?: boolean
  onOpen?: () => void
  onClose?: () => void
}

const Tooltip = ({ title, open, onOpen, onClose, children }: TooltipProps) => {
  return (
    <MuiTooltip title={title} open={open} onOpen={onOpen} onClose={onClose}>
      {children}
    </MuiTooltip>
  )
}

export default Tooltip
