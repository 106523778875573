import React, { ChangeEvent, CSSProperties, useState } from 'react'

import TextField from '../../ui/TextField/TextField'
import Text from '../../locale/strings'
import SearchIcon from '@mui/icons-material/Search'
import { KEYBOARD_KEY } from '../../common/constants'

import styles from './searchBar.module.scss'

export interface OptionType {
  label: string
  value: string
}

interface SearchBarProps {
  filter: string
  onChangeFilter: (value: string) => void
  onSearch: (value: string) => void
  modalView?: boolean
  label?: string
  styles?: CSSProperties
  hideResults?: boolean
  children?: React.ReactElement[] | React.ReactElement
}

const SearchBar: React.FC<SearchBarProps> = ({
  modalView,
  label = Text.common.search,
  filter,
  onChangeFilter,
  onSearch,
  styles: extraStyles = {},
  hideResults,
  children,
}: SearchBarProps) => {
  const [error, setError] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')

  const handleChangeFilter = (event: ChangeEvent<HTMLInputElement>) => {
    const forbiddenCharacters = [',', '%', '*', ' ', '.', '!', ';', '?']
    //check if input length is more then 150 characters

    setError(false)
    setErrorMessage('')

    const value = event.target.value

    if (value.length >= 151) {
      setError(true)

      return
    }

    onChangeFilter(value)

    if (forbiddenCharacters.includes(value)) {
      setErrorMessage(`This entry: ${value} is not allowed`)
    }
  }

  const handleSearch = () => {
    if (error || errorMessage !== '') {
      return
    }

    onSearch(filter)
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === KEYBOARD_KEY.ENTER) {
      handleSearch()
    }
  }

  const isModal: boolean = modalView !== undefined ? modalView : false

  return (
    <div className={`${styles.container} ${isModal ? styles.inModal : ''}`}>
      <div className={styles.innerContainer}>
        <TextField
          fullWidth
          className={styles.searchInput}
          label={label}
          value={filter}
          error={error ? Text.errors.maxLength150 : errorMessage !== '' ? errorMessage : undefined}
          onChange={handleChangeFilter}
          onKeyPress={handleKeyPress}
        />
        <SearchIcon className={(styles.SearchIcon, styles.searchCustomIcon)} onClick={handleSearch} />
      </div>
      <div className={styles.searchResults}>{!hideResults && children}</div>
    </div>
  )
}

export default SearchBar
