import React from 'react'
import { observer } from 'mobx-react'

import LoadingPage from '../ui/Loading/LoadingPage'
import ErrorPage from '../error/page/ErrorPage'
import useStores from '../common/hook/useStore'

interface DeviceRegisterProps {
  children: React.ReactNode
}

const DeviceRegister: React.FC<DeviceRegisterProps> = ({ children }) => {
  const { deviceStore } = useStores()

  return (
    <React.Fragment>
      {deviceStore.isPending && <LoadingPage />}

      {deviceStore.isError && <ErrorPage />}

      {!deviceStore.isPending && !deviceStore.isError && children}
    </React.Fragment>
    // <Choose>
    //   <When condition={deviceStore.isPending}>
    //     <LoadingPage />
    //   </When>
    //   <When condition={deviceStore.isError}>
    //     <ErrorPage error={deviceStore.errorMessage} />
    //   </When>
    //   <Otherwise>{children}</Otherwise>
    // </Choose>
  )
}

export default observer(DeviceRegister)
