import { ErrorCode } from '../../common/errors'

export const getCustomErrorNotificationByCode = (code: number): Promise<string> => {
  let message = ''

  switch (true) {
    case code === ErrorCode.TRACK_ALREADY_EXISTS_IN_PLAYLIST:
      message = 'Track is already present in the playlist'
      break
    case code === ErrorCode.INVALID_PLAYLIST_FILE_FORMAT:
      message = 'The format of the playlist you uploaded and the playlist type do not match'
      break
    case code === ErrorCode.USER_WITH_SAME_EMAIL_ALREADY_REGISTERED:
      message = 'Email is invalid or already taken'
      break
    case code === ErrorCode.PLAYLIST_NAME_ALREADY_EXISTS:
      message = 'Playlist with the same name is already connected to an app'
      break
    case code === ErrorCode.MAX_STREAM_MINUTES_EXCEEDED:
      message = 'Provided value for available stream minutes exceeds the maximum allowed value'
      break
    case code === ErrorCode.REQUIRED_STREAM_MINUTES_NOT_PRVOIDED:
      message = 'Available stream minutes value that is required for selected app price product type is not provided'
      break
    case code === ErrorCode.REQUIRED_STREAM_COUNT_NOT_PRVOIDED:
      message = 'Available stream count value that is required for selected app price product type is not provided'
      break
    case code === ErrorCode.TRACK_NOT_AVAILABLE:
      message = 'Selected file format not available for download'
      break
    case code === ErrorCode.PROVIDED_INPUT_CAN_NOT_BE_MAPPED_TO_TARGET_DEFINITION:
      message = 'Provided input can not be mapped to target definition'
      break
    case code === ErrorCode.AT_LEAST_ONE_REPEATABLE_AD_BLOCK:
      message = `Please enter a track value greater than 0 in at least one
      repeatable ad block when the repeatable ad count is greater than 0`

      break
    case code === ErrorCode.AD_WITH_PROVIDED_ID_DOES_NOT_EXIST:
      message = 'Ad with provided ID does not exist'
      break
    case code === ErrorCode.APPLICATION_WITH_SAME_NAME_ALREADY_REGISTERED_WITHIN_COMPANY:
      message = 'Application with provided name already registered within this company'
      break
    case code === ErrorCode.COMPANY_WITH_SAME_NAME_ALREADY_EXIST:
      message = 'Company with the same name already exists'
      break
    case code === ErrorCode.SOUNDBOARD_WITH_SAME_NAME_ALREADY_REGISTERED:
      message = 'Soundboard with the same name already exists'
      break
    case code === ErrorCode.SOUNDBOARD_STYNG_ALREADY_ADDED_TO_CATEGORY:
      message = 'Styng is already added to this soundboard category'
      break
    case code === ErrorCode.SOUNDBOARD_WITH_SAME_TYPE_IS_ALREADY_PUBLISHED_FOR_SELECTED_APP:
      message = 'Soundboard with same type is already published for selected application'
      break
    case code === ErrorCode.PLATFORM_DUPLICATE_NAME_PROVIDED:
      message = 'The provided platform name already exists'
      break
    case code === ErrorCode.PLATFORM_DUPLICATE_OFFER_CODE_PREFIX_PROVIDED:
      message = 'The provided platform offer code prefix already exists'
      break
    case code === ErrorCode.STYNG_WITH_SAME_NAME_ALREADY_EXIST:
      message = 'Styng with provided name already exists'
      break
    default:
      message = 'Something went wrong...'
  }

  return Promise.resolve(message)
}
