export const purgeEmptyStringProps = (data: any) => {
  for (const key in data) {
    if (data[key] === '') {
      delete data[key]
    } else if (typeof data[key] === 'object' && data[key] !== null) {
      purgeEmptyStringProps(data[key])

      if (Object.keys(data[key]).length === 0) {
        delete data[key]
      }
    }
  }

  return data
}
