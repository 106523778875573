import { defaultImg } from '../../../common/constants'

import useStores from '../../../common/hook/useStore'
import { EditCurrencyRoute } from '../../pages'

import styles from './currencyItem.module.scss'
import useImage from '../../../common/hook/useImage'

interface StyngItemProps {
  id: number
  name: string
  appId: string
  imageId: string
}

const StyngItem = ({ id, name, appId, imageId }: StyngItemProps) => {
  const { navigationStore } = useStores()

  const handleClickStyng = () => {
    navigationStore.goToPage(EditCurrencyRoute.getUrl({ currencyId: id }))
  }

  const { data: imageData } = useImage(imageId)
  const imageUrl = imageData?.url ?? defaultImg

  return (
    <div className={styles.container} onClick={handleClickStyng}>
      <div className={styles.picture}>
        <img src={imageUrl} alt={imageId} loading="lazy" />
      </div>
      <div className={styles.infoContainer}>
        <h4 className={styles.title}>{name}</h4>
      </div>
      <div className={styles.timeContainer}>
        <h5>{appId}</h5>
      </div>
    </div>
  )
}

export default StyngItem
