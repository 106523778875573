import { Identity } from 'common/api/common/common'

export interface LabelOwner extends Identity {
  mediaNetId: number
}

type LabelOwnerEntity = Exclude<LabelOwner, 'id'>

export interface LabelOwnersResponse {
  labelOwners: LabelOwnerEntity[]
}

export const toLabelOwner = (entity: LabelOwnerEntity): LabelOwner => ({
  ...entity,
  id: entity.id,
})
