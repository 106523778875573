import axios, { AxiosRequestConfig } from 'axios'

import logger from 'lib/logger'
import { ApiError } from './error'

const retrieve = async (props: AxiosRequestConfig, hasRetriedAfterAuthentication = false): Promise<any> => {
  try {
    const { data } = await axios(props)

    return data
  } catch (err: any) {
    if (err.message === 'Request failed with status code 401') {
      window.history.go(-(window.history.length - 1))
      window.location.replace('/login')
      throw new ApiError(err)
    }

    if (err?.hasAuthenticated && !hasRetriedAfterAuthentication) {
      return retrieve(props, true)
    }

    throw new ApiError(err)
  }
}

const request = (props: AxiosRequestConfig, { throwOnError = true } = {}) => {
  // logger.debug(props, `throwOnError: ${throwOnError}`)

  return retrieve(props)
}

export default request
