import { observer } from 'mobx-react'

import LoadingComponent from '../ui/Loading/LoadingPage'
import ErrorLoading from '../ui/ErrorLoading/ErrorLoading'
import { useStyngsQuery } from './StyngsStore'
import useStores from 'common/hook/useStore'

const StyngsHOC = (Component: React.FC) => {
  const Styngs: React.FC = ({ children, ...props }) => {
    const { styngsStore } = useStores()
    const query = useStyngsQuery(styngsStore)

    return (
      <Choose>
        <When condition={query.isLoading}>
          <LoadingComponent />
        </When>
        <When condition={!query.isError}>
          <Component {...props}>{children}</Component>
        </When>
        <Otherwise>
          <ErrorLoading />
        </Otherwise>
      </Choose>
    )
  }

  return observer(Styngs)
}

export default StyngsHOC
