import { useMutation } from 'react-query'

import API from '../../common/api'
import useStores from '../../common/hook/useStore'

import { ImageEntity } from '../../common/api/image/image'

export const useCreateImageMutation = (onSuccess: any) => {
  const { notificationsStore } = useStores()

  return useMutation<ImageEntity, Error, FormData>(
    (body: FormData) => {
      return API.image.create(body)
    },
    {
      onSuccess: (data) => {
        if (onSuccess) {
          onSuccess(data)
        }
      },
      onError: (error) => {
        notificationsStore.errorNotification(error)
      },
    },
  )
}
