import strings from '../locale/strings'
import Route from 'lib/routing/Route'

import ActivityLog from './pages/ActivityLog'

export const ActivityLogRoute: Route = {
  path: '/activity-log',
  name: strings.navigation.activityLog,
  exact: true,
  component: ActivityLog,
}
