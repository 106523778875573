import { Company } from '../company/company'

export interface ProfileResponse {
  user: User
  roles: Role[]
}

export enum Role {
  ADMIN = 'styngr:cms:admin',
  CONTENT = 'styngr:cms:content',
}

export interface User {
  userId: string
  firstName: string
  lastName: string
  middleName: Nullable<string>
  email: string
  companies: Company[]
  imageId?: string
  roles?: Role[]
}

export interface EditUserResponse {
  user: User
}

export interface UserResponse {
  items: []
  pageCount: number
  pageNumber: number
}

export interface EditUserRequest {
  firstName: string
  lastName: string
  middleName: Nullable<string>
  userId?: string
}

export interface EditUserResponse {
  userId: string
  firstName: string
  lastName: string
  middleName: Nullable<string>
  email?: string
  companyId?: string
}

export interface EditUserEntity {
  firstName: string
  lastName: string
  middleName: Nullable<string>
  email: string
  userId?: string
  // TODO needs after turn on multiselect for company
  // companies: Company[]
}

export interface DeleteUser {
  userId: string
}
