import Route from 'lib/routing/Route'

import LoginPage from '../../../auth/pages/Login/LoginPage'

export const LoginRoute: Route = {
  path: '/login',
  name: 'login',
  exact: true,
  component: LoginPage,
}

export default LoginPage
