/* eslint-disable jsx-control-statements/jsx-for-require-each */
import { observer } from 'mobx-react'

import { thread } from 'lib/fn/thread'

import Text from '../../locale/strings'

import TrackItem from './TrackItem/Trackitem'
import { Track } from '../../common/api/track/track'
import LoadingRemote from '../../common/LoadingRemote'
import EditStyngStore from './CreateStyngStore'
import { useLsrTracksQuery } from '../../tracks/TracksStore'

interface StyngsFilterProps {
  editStyngStore: EditStyngStore
  onClickTrack: (styng: Track) => void
}

const LsrTracksFilter = ({ editStyngStore, onClickTrack }: StyngsFilterProps) => {
  const { data, status } = useLsrTracksQuery(editStyngStore)

  const tracks = data?.tracks ?? []

  return (
    <LoadingRemote status={status}>
      <Choose>
        <When condition={tracks.length > 0}>
          <For
            of={tracks}
            body={(track: Track) => <TrackItem key={track.trackId} track={track} onClick={onClickTrack} />}
          />
        </When>
        <When condition={tracks.length === 0 && status === 'success'}>
          <div>
            <p>{Text.search.emptyData}</p>
          </div>
        </When>
      </Choose>
    </LoadingRemote>
  )
}

export default thread(LsrTracksFilter, [observer])
