/* eslint-disable import/no-anonymous-default-export */
import { Role } from '../common/api/user/user'
import { Permission } from '../common/constants'

const {
  CREATE_STYNG,
  CREATE_STYNGLIST,
  USERS_VIEW,
  COMPANIES_VIEW,
  PLAYLISTS_VIEW,
  PAGE_VIEW,
  APP_EVENTS_VIEW,
  CREATE_APP_EVENT,
  CREATE_APP,
  EDIT_APP,
  APP_VIEW,
  EDIT_COMPANY,
  EDIT_APP_STYNGLIST,
} = Permission

export default {
  [Role.ADMIN]: [
    CREATE_STYNG,
    CREATE_STYNGLIST,
    USERS_VIEW,
    COMPANIES_VIEW,
    PLAYLISTS_VIEW,
    APP_EVENTS_VIEW,
    CREATE_APP_EVENT,
    PAGE_VIEW,
    CREATE_APP,
    EDIT_APP,
    APP_VIEW,
    EDIT_COMPANY,
    EDIT_APP_STYNGLIST,
  ],
  [Role.CONTENT]: [PLAYLISTS_VIEW, PAGE_VIEW, APP_VIEW],
}
