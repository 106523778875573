import Route from 'lib/routing/Route'
import MainPage from './MainPage'

export const MainRoute: Route = {
  path: '/',
  name:'MainPage',
  component: MainPage,
  exact: true,
}

export default MainPage
