import React, { Dispatch, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import { useFormik } from 'formik'
import * as yup from 'yup'
import Text from '../../locale/strings'
import TextField from '../../ui/TextField/TextField'
import Table from 'ui/Table/Table'
import Divider from '@mui/material/Divider'
import Chip from '@mui/material/Chip'

import { DateTime } from 'luxon'
import { AppPricePlan, PricePlanValues } from '../../common/api/app/app'

import styles from './pricePlan.module.scss'

const schema = yup.object({
  revenueShare: yup
    .number()
    .min(0, 'Revenue share must be greater than or equal to 0')
    .max(1, 'Revenue share  must be less than or equal to 1')
    .required('Revenue share is required'),
  deductionShare: yup
    .number()
    .min(0, 'Deduction share must be greater than or equal to 0')
    .max(1, 'Deduction share  must be less than or equal to 1')
    .required('Deduction share is required'),
  taxShare: yup
    .number()
    .min(0, 'Tax share must be greater than or equal to 0')
    .max(1, 'Tax share  must be less than or equal to 1')
    .required('Tax share is required'),
  pricePerStream: yup
    .number()
    .min(0, 'Price per stream must be greater than or equal to 0')
    .max(1000000, 'Price per stream  must be less than or equal to 1 000 000')
    .required('Price per stream is required'),
})

interface PricePlanProps {
  pricePlan: PricePlanValues
  initialPricePlan: AppPricePlan[] | undefined
  setNewPricePlan: Dispatch<React.SetStateAction<PricePlanValues>>
}

const PricePlan = ({ pricePlan, initialPricePlan, setNewPricePlan }: PricePlanProps) => {
  const [showPricePlanTable, setShowPricePlanTable] = useState<boolean>(false)

  const formik = useFormik<PricePlanValues>({
    initialValues: pricePlan,
    validationSchema: schema,
    validateOnBlur: false,
    validateOnChange: true,
    onSubmit: () => {},
  })

  const { values } = formik

  const columns = ['Revenue share %', 'Deduction share %', 'Price per stream $', 'Tax share %', 'Start at', 'End at']

  const dataTable = initialPricePlan?.map((pricePlanObject: any) => [
    pricePlanObject.revenueShare,
    pricePlanObject.deductionShare,
    pricePlanObject.pricePerStream,
    pricePlanObject.taxShare,
    DateTime.fromISO(pricePlanObject.startAt).toFormat('ff'),
    pricePlanObject.endAt === null ? 'present' : DateTime.fromISO(pricePlanObject.endAt).toFormat('ff'),
  ])

  useEffect(() => {
    setNewPricePlan(values)
  }, [values])

  return (
    <Box>
      <div className={styles.container}>
        <div className={styles.form}>
          <div className={styles.wrapper}>
            <TextField
              data-test="revenue-share-field"
              type="number"
              label={Text.page.appEvents.create.fields.revenueShare}
              name="revenueShare"
              value={values.revenueShare}
              className={styles.input}
              error={formik.errors.revenueShare}
              onChange={formik.handleChange}
            />
            <TextField
              data-test="deduction-share-field"
              type="number"
              label={Text.page.appEvents.create.fields.deductionShare}
              name="deductionShare"
              autoComplete="off"
              value={values.deductionShare}
              className={styles.input}
              error={formik.errors.deductionShare}
              onChange={formik.handleChange}
            />
            <TextField
              data-test="tax-share-field"
              type="number"
              label={Text.page.appEvents.create.fields.taxShare}
              name="taxShare"
              autoComplete="off"
              value={values.taxShare}
              className={styles.input}
              error={formik.errors.taxShare}
              onChange={formik.handleChange}
            />
            <TextField
              data-test="price-per-stream-field"
              type="number"
              label={Text.page.appEvents.create.fields.pricePerStream}
              name="pricePerStream"
              autoComplete="off"
              value={values.pricePerStream}
              className={styles.input}
              error={formik.errors.pricePerStream}
              onChange={formik.handleChange}
            />
          </div>
        </div>
        {initialPricePlan && (
          <Divider textAlign="center" sx={{ margin: 1 }} onClick={() => setShowPricePlanTable(!showPricePlanTable)}>
            <Chip sx={{ cursor: 'pointer' }} label={'Price plan history'} />
          </Divider>
        )}
        {showPricePlanTable && (
          <div>
            <Table hideActionColumn stickyHeader size="small" tableMaxHeight={250} columns={columns} data={dataTable} />
          </div>
        )}
      </div>
    </Box>
  )
}

export default PricePlan
