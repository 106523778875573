import Currencies from '../Currencies/Currencies'
import { useCurrenciesQuery } from '../hooks'
import LoadingRemote from '../../common/LoadingRemote'
// import * as R from 'ramda'

const CurrenciesPage: React.FC = () => {
  const { data, status } = useCurrenciesQuery()
  const currencies = data ?? []

  return (
    <LoadingRemote status={status}>
      <Currencies currencies={currencies} />
    </LoadingRemote>
  )
}

export default CurrenciesPage
