import { Identity } from 'common/api/common/common'

export interface Album extends Identity {
  mediaNetId: number
  title: string
  status: string
  releaseDate: string
}

type AlbumEntity = Exclude<Album, 'id'>

export interface AlbumsResponse {
  albums: AlbumEntity[],
}

export const toAlbum = (entity: AlbumEntity) => ({
  ...entity,
  id: entity.mediaNetId,
  name: entity.title,
})
