/* eslint-disable @typescript-eslint/indent */
import { useQuery } from 'react-query'
import { action, computed, observable } from 'mobx'
import * as R from 'ramda'

import { thread } from 'lib/fn'
import { TracksResponse } from '../common/api/track/track'
import API from '../common/api'
import PaginationStore from '../common/PaginationStore'
import { Identity, TypedIdentity } from '../common/api/common/common'
import { Genre } from '../common/api/genre/genre'
import { Album } from '../common/api/album/album'
import { Artist } from '../common/api/artist/artist'
import { Country } from '../common/api/country/country'
import { Label } from '../common/api/label/label'
import { LabelOwner } from '../common/api/labelOwner/labelOwner'

const prepareTypedIdentity =
  (type: string) =>
  (identity: Identity): TypedIdentity => ({
    ...identity,
    typeIdentity: type,
  })

export enum FilterType {
  LABEL = 'Label',
  GENRE = 'Genre',
  ARTIST = 'Artist',
  ALBUM = 'Album',
  COUNTRY = 'Country',
  LABELOWNER = 'Label owner',
  ISRC = 'Isrc',
  MEDIANETID = 'MediaNet ID',
  PARENTALADVISORY = 'Explicit Content',
}

class TracksForStyngsStore extends PaginationStore {
  @observable
  activeLabels: Label[] = []
  @observable
  activeLabelOwners: LabelOwner[] = []
  @observable
  activeGenres: Genre[] = []
  @observable
  activeArtists: Artist[] = []
  @observable
  activeAlbums: Album[] = []
  @observable
  activeCountries: Country[] = []
  @observable
  isrcFilter: any[] = []
  @observable
  mediaNetIDFilter: any[] = []
  @observable
  parentalAdvisory: boolean | null = null

  @computed
  get labels(): TypedIdentity[] {
    return [
      ...R.map(prepareTypedIdentity(FilterType.LABEL), this.activeLabels),
      ...R.map(prepareTypedIdentity(FilterType.LABELOWNER), this.activeLabelOwners),
      ...R.map(prepareTypedIdentity(FilterType.GENRE), this.activeGenres),
      ...R.map(prepareTypedIdentity(FilterType.ARTIST), this.activeArtists),
      ...R.map(prepareTypedIdentity(FilterType.ALBUM), this.activeAlbums),
      ...R.map(prepareTypedIdentity(FilterType.COUNTRY), this.activeCountries),
    ]
  }

  @action
  reset = () => {
    this.page = 1
    this.pageSize = 30
    this.filter = ''
    this.activeLabels = []
    this.activeLabelOwners = []
    this.activeGenres = []
    this.activeArtists = []
    this.activeAlbums = []
    this.activeCountries = []
    this.isrcFilter = []
    this.mediaNetIDFilter = []
    this.parentalAdvisory = null
  }

  @action
  addLabel = (label: Label) => {
    if (R.isNil(R.find(R.equals(label), this.activeLabels))) {
      this.activeLabels.push(label)
      this.changePage()
    }
  }

  @action
  removeLabel = (label: Identity) => {
    const index = R.findIndex(R.eqProps('id', label), this.activeLabels)

    if (index >= 0) {
      this.activeLabels = R.remove(index, 1, this.activeLabels)
      this.changePage()
    }
  }

  @action
  addLabelOwner = (label: LabelOwner) => {
    if (R.isNil(R.find(R.equals(label), this.activeLabelOwners))) {
      this.activeLabelOwners.push(label)
      this.changePage()
    }
  }

  @action
  removeLabelOwner = (label: Identity) => {
    const index = R.findIndex(R.eqProps('id', label), this.activeLabelOwners)

    if (index >= 0) {
      this.activeLabelOwners = R.remove(index, 1, this.activeLabelOwners)
      this.changePage()
    }
  }

  @action
  addGenre = (genre: Genre) => {
    if (R.isNil(R.find(R.equals(genre), this.activeGenres))) {
      this.activeGenres.push(genre)
      this.changePage()
    }
  }

  @action
  removeGenre = (genre: Identity) => {
    const index = R.findIndex(R.eqProps('id', genre), this.activeGenres)

    if (index >= 0) {
      this.activeGenres = R.remove(index, 1, this.activeGenres)
      this.changePage()
    }
  }

  @action
  changeGenres = (genres: Genre[]) => {
    this.activeGenres = genres
  }

  @action
  addArtist = (artist: Artist) => {
    if (R.isNil(R.find(R.equals(artist), this.activeArtists))) {
      this.activeArtists.push(artist)
      this.changePage()
    }
  }

  @action
  removeArtist = (artist: Identity) => {
    const index = R.findIndex(R.eqProps('id', artist), this.activeArtists)

    if (index >= 0) {
      this.activeArtists = R.remove(index, 1, this.activeArtists)
      this.changePage()
    }
  }

  @action
  addAlbum = (album: Album) => {
    if (R.isNil(R.find(R.equals(album), this.activeAlbums))) {
      this.activeAlbums.push(album)
      this.changePage()
    }
  }

  @action
  removeAlbum = (album: Identity) => {
    const index = R.findIndex(R.eqProps('id', album), this.activeAlbums)

    if (index >= 0) {
      this.activeAlbums = R.remove(index, 1, this.activeAlbums)
      this.changePage()
    }
  }

  @action
  addCountry = (country: Country) => {
    if (R.isNil(R.find(R.equals(country), this.activeCountries))) {
      this.activeCountries.push(country)
      this.changePage()
    }
  }

  @action
  removeCountry = (country: Identity) => {
    const index = R.findIndex(R.eqProps('id', country), this.activeCountries)

    if (index >= 0) {
      this.activeCountries = R.remove(index, 1, this.activeCountries)
      this.changePage()
    }
  }

  @action
  handleIsrcSearch = (filter: any[]) => {
    this.isrcFilter = filter
    this.changePage()
  }

  @action
  handleMediaNetIDSearch = (filter: any[]) => {
    this.mediaNetIDFilter = filter
    this.changePage()
  }

  @action
  setParentalAdvisory = (filter: boolean | null) => {
    this.parentalAdvisory = filter
    this.changePage()
  }

  handleDeleteFilter = (type: string, identity: Identity) => () => {
    switch (type) {
      case FilterType.GENRE:
        return this.removeGenre(identity)
      case FilterType.ARTIST:
        return this.removeArtist(identity)
      case FilterType.ALBUM:
        return this.removeAlbum(identity)
      case FilterType.LABEL:
        return this.removeLabel(identity)
      case FilterType.LABELOWNER:
        return this.removeLabelOwner(identity)
      case FilterType.COUNTRY:
        return this.removeCountry(identity)
    }
  }
}

const prepareIds = (values: Identity[]) => thread(values, [R.map(R.prop('id')), R.join(',')])

const prepareCodes = (values: Identity[]) => thread(values, [R.map(R.prop('code')), R.join(',')])

export const useLsrTracksPageQuery = (filterStore: TracksForStyngsStore, format = 'AAC') => {
  const {
    pageSize,
    page,
    filter,
    isrcFilter,
    activeGenres,
    activeAlbums,
    activeArtists,
    activeCountries,
    activeLabels,
    activeLabelOwners,
    mediaNetIDFilter,
    parentalAdvisory,
  } = filterStore

  return useQuery<TracksResponse>(
    [
      'lsr-tracks-filter',
      pageSize,
      page,
      filter,
      format,
      isrcFilter,
      activeGenres,
      activeArtists,
      activeAlbums,
      activeCountries,
      activeLabels,
      activeLabelOwners,
      mediaNetIDFilter,
      parentalAdvisory,
    ],
    () => {
      return API.track.lsrList({
        page,
        size: pageSize,
        title: filter,
        format: format,
        mediaNetIds: mediaNetIDFilter.toString(),
        isrcCodes: isrcFilter.toString(),
        genreIds: prepareIds(activeGenres),
        artistIds: prepareIds(activeArtists),
        albumIds: prepareIds(activeAlbums),
        territoryCodes: prepareCodes(activeCountries),
        labelIds: prepareIds(activeLabels),
        labelOwnerIds: prepareIds(activeLabelOwners),
        parentalAdvisory: parentalAdvisory,
      })
    },
    {
      keepPreviousData: false,
      retry: 1,
      refetchOnWindowFocus: false,
    },
  )
}

export default TracksForStyngsStore
