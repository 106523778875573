import { useState } from 'react'

import { Category } from 'common/api/soundboard/soundboard'
import { Styng, StyngsResponse, RoyaltyFreeStyng, RoyaltyFreeStyngsResponse } from 'common/api/styng/styng'

import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DatePicker from '@mui/lab/DatePicker'
import DateAdapter from '@mui/lab/AdapterLuxon'

import { DateTime } from 'luxon'

import { Tooltip, Box, TextField as MuiTextField } from '@mui/material'

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'

import TextField from 'ui/TextField/TextField'
import Pagination from 'ui/Pagination/Pagination'
import Button, { ButtonVariant } from '../../../ui/Button/Button'

import styles from './Categories.module.scss'

const backIcon = <KeyboardBackspaceIcon fontSize="small" />
const addIcon = <AddIcon fontSize="small" />

interface CategoriesProps {
  styngs: StyngsResponse | RoyaltyFreeStyngsResponse | undefined
  categoriesList: Category[]
  addNewCategory: (categoryName: string) => void
  removeCategory: (index: number) => void
  addStyngToCategory: (styng: any, selectedCategoryIndex: number | null) => void
  setExpirationDate: (value: any, styngId: string, selectedCategoryIndex: number) => void
  removeStyngFromCategory: (styngId: string, selectedCategoryIndex: number) => void
  styngsStore: any
  styngsPagesCount: number
  handleStyngsChangePage: (value: number) => void
}

const Categories = ({
  styngs,
  categoriesList,
  addNewCategory,
  removeCategory,
  addStyngToCategory,
  setExpirationDate,
  removeStyngFromCategory,
  styngsStore,
  styngsPagesCount,
  handleStyngsChangePage,
}: CategoriesProps) => {
  const [categorySelected, setCategorySelected] = useState<boolean>(false)
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState<number | null>(null)
  const [categoryName, setCategoryName] = useState<string>('')
  const [addCategoryInput, setAddCategoryInput] = useState<string>('')

  const categoryBackButton = () => {
    setCategorySelected(false)
    setSelectedCategoryIndex(null)
  }

  const selectCategory = (categoryName: string, categoryIndex: number) => {
    setCategorySelected(true)
    setCategoryName(categoryName)

    setSelectedCategoryIndex(categoryIndex)
  }

  const handleAddCategory = (e: React.FormEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value

    setAddCategoryInput(newValue)
  }

  const handleKeyDown = (event: KeyboardEvent) => {
    event.preventDefault()
  }

  const handleAddNewCategory = () => {
    addNewCategory(addCategoryInput.trim())
    setAddCategoryInput('')
  }

  const renderInputDefault = (props: any) => {
    const currentDay = DateTime.now()
    const selectedDate = DateTime.fromFormat(props.inputProps.value, 'M/d/yyyy')
    const dffInDays = selectedDate.diff(currentDay, 'days').days

    return (
      <Tooltip placement="right-end" title={`${dffInDays < 7 ? 'Styng expires in less than 7 days' : ''}`}>
        <MuiTextField
          data-test="date-field"
          className={`${dffInDays < 7 ? styles.regularDate && styles.focusDate : styles.regularDate}`}
          onKeyDown={handleKeyDown}
          {...props}
        />
      </Tooltip>
    )
  }

  return (
    <Box className={styles.categoriesWrapper}>
      <Box className={styles.categoriesContentWrapper}>
        <Box className={styles.categoriesHeader}>
          {categorySelected && (
            <Button
              sx={{ height: 40, width: 40 }}
              className={styles.categoriesHeaderItem}
              variant={ButtonVariant.OUTLINED}
              onClick={() => categoryBackButton()}
            >
              {backIcon}
            </Button>
          )}
          <div className={styles.categoryHeaderName}>
            {categorySelected ? <p>{categoryName}</p> : <p>Choose category</p>}
          </div>
          {!categorySelected && (
            <div className={styles.addCategoryFieldWrapper}>
              <TextField
                data-test="add-category-field"
                autoComplete="off"
                className={styles.addCategoryField}
                label={'Add category'}
                name="addCategory"
                value={addCategoryInput}
                size="small"
                inputProps={{ maxLength: 60 }}
                onChange={(e) => handleAddCategory(e)}
              />
              <Button
                data-test="add-category-button"
                sx={{ height: 40, width: 40 }}
                variant={ButtonVariant.OUTLINED}
                onClick={handleAddNewCategory}
              >
                {addIcon}
              </Button>
            </div>
          )}
        </Box>
        <Box className={styles.folder}>
          {!categorySelected ? (
            <div className={styles.folderParent}>
              {categoriesList.map((option, index) => (
                <p className={styles.parentItem} key={option.name}>
                  <span className={styles.parentItemName}>{option.name}</span>
                  {option?.styngs?.length ? (
                    <span className={styles.parentItemStyngs}> ({option?.styngs?.length})</span>
                  ) : (
                    <span className={styles.parentItemStyngs}> (0)</span>
                  )}
                  <span className={styles.actions}>
                    <EditIcon
                      data-test="edit-category-button"
                      className={styles.editIcon}
                      onClick={() => selectCategory(option.name, index)}
                    />
                    <DeleteIcon
                      data-test="delete-category-button"
                      className={styles.deleteIcon}
                      onClick={() => removeCategory(index)}
                    />
                  </span>
                </p>
              ))}
            </div>
          ) : (
            <div className={styles.folderChildWrapper}>
              <div className={styles.folderChild}>
                <p className={styles.childItem}>
                  <span>Styng name</span>
                  <span>Expiration Date</span>
                </p>
              </div>
              <div className={styles.folderChild}>
                {selectedCategoryIndex !== null &&
                  categoriesList[selectedCategoryIndex]?.styngs.map((styng: any, index: number) => (
                    <div className={styles.childItem} key={index}>
                      <span>{styng.name}</span>
                      <LocalizationProvider dateAdapter={DateAdapter}>
                        <DatePicker
                          disableHighlightToday
                          value={styng.expiresAt ? styng.expiresAt : null}
                          renderInput={renderInputDefault}
                          minDate={DateTime.now()
                            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                            .plus({ days: 1 })}
                          onChange={(newValue) => setExpirationDate(newValue, styng.id, selectedCategoryIndex)}
                        />
                      </LocalizationProvider>
                      <span
                        className={styles.removeStyng}
                        onClick={() => removeStyngFromCategory(styng.id, selectedCategoryIndex)}
                      >
                        <RemoveIcon data-test="edit-styng-button" className={styles.removeStyngIcon} />
                      </span>
                    </div>
                  ))}
              </div>
            </div>
          )}
        </Box>
      </Box>
      <Box className={styles.categoriesContentWrapper}>
        <Box className={styles.styngsHeader}>
          <p>Styngs</p>
        </Box>
        <div className={styles.styngRow}>
          <div className={styles.addStyng} />
          <span className={styles.styngItem}>ID</span>
          <span className={styles.styngItem}>Name</span>
        </div>
        {styngs?.items.map((styng: Styng | RoyaltyFreeStyng) => (
          <div className={styles.styngRow} key={styng.id}>
            <div className={styles.addStyng} onClick={() => addStyngToCategory(styng, selectedCategoryIndex)}>
              <AddIcon data-test="add-styng-button" className={styles.addStyngIcon} />
            </div>
            <span className={styles.styngItem}>{styng.id}</span>
            <span className={styles.styngItem}>{styng.name}</span>
          </div>
        ))}
        <If condition={styngsPagesCount > 1}>
          <div className={styles.paginationContainer}>
            <div className={styles.pagination}>
              <Pagination page={styngsStore.page} count={styngsPagesCount} onChange={handleStyngsChangePage} />
            </div>
          </div>
        </If>
      </Box>
    </Box>
  )
}

export default Categories
