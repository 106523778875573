import strings from 'locale/strings'
import Route, { ParametrizedRoute } from 'lib/routing/Route'

import Soundboard from './pages/Soundboard'
import CreateSoundboard from './pages/CreateSoundboard/CreateSoundboard'
import EditSoundboard from './pages/EditSoundboard/EditSoundboard'

export const SoundboardRoute: Route = {
  path: '/soundboard',
  name: strings.navigation.soundboard,
  exact: true,
  component: Soundboard,
}

export const CreateSoundboardRoute: Route = {
  path: '/soundboard/create',
  name: strings.navigation.createSoundboard,
  exact: true,
  component: CreateSoundboard,
}

export const EditSoundboardRoute: ParametrizedRoute = {
  path: '/soundboard/edit/:id',
  name: strings.navigation.createSoundboard,
  exact: true,
  component: EditSoundboard,
  getUrl: ({ id }) => `/soundboard/edit/${id}`,
}
