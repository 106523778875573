import { getCustomErrorNotificationByCode } from 'ui/Snackbar/SnackbarHelper'
import PaginationStore from '../common/PaginationStore'
import { observable, action } from 'mobx'

class NotificationsStore extends PaginationStore {
  @observable notifications: any[] = []

  @action
  successNotification(message: string, autoHideDuration: number = 4000) {
    this.notifications.push({ message, type: 'success', autoHideDuration })
  }

  @action
  errorNotification = async (error: any, autoHideDuration: number = 4000) => {
    const errorObj = JSON.stringify(error)
    const parseObj = JSON.parse(errorObj)
    const errorCode = parseObj.errorCode

    const message = await getCustomErrorNotificationByCode(errorCode)

    this.notifications.push({ message, type: 'error', autoHideDuration })
  }

  @action
  errorNotificationCode = async (errorCode: number, autoHideDuration: number = 4000) => {
    const message = await getCustomErrorNotificationByCode(errorCode)

    this.notifications.push({ message, type: 'error', autoHideDuration })
  }

  @action
  errorNotificationText = (message: string, autoHideDuration: number = 4000) => {
    this.notifications.push({ message, type: 'error', autoHideDuration })
  }

  @action
  infoNotification(message: string, autoHideDuration: number = 4000) {
    this.notifications.push({ message, type: 'info', autoHideDuration })
  }

  @action
  removeNotification(index: number) {
    this.notifications.splice(index, 1)
  }
}

export default NotificationsStore
