import Button from '../../ui/Button/Button'
import Text from '../../locale/strings'
import useStores from '../../common/hook/useStore'
import { Currency } from '../../common/api/currency/currency'
import { CreateCurrencyRoute } from '../../currency/pages'
import CurrencyItem from './CurrencyItem/CurrencyItem'

import styles from './currencies.module.scss'

interface CurrenciesProps {
  currencies: Currency[]
}

const Currencies = ({ currencies }: CurrenciesProps) => {
  const { navigationStore } = useStores()

  const handleNewCurrency = () => {
    navigationStore.goToPage(CreateCurrencyRoute.path)
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Button onClick={handleNewCurrency}>{Text.navigation.createCurrency}</Button>
      </div>
      <div className={styles.currencies}>
        {/* <For of={currencies} body={(currency: Currency) => (
          <CurrencyItem
            key={currency.id}
            id={currency.id}
            name={currency.name}
            appId={currency.appId}
            imageId={currency.imageId}
          />
          )}
        /> */}
        {currencies.map((currency: Currency, idx) => {
          return (
            <CurrencyItem
              key={currency.id}
              id={currency.id}
              name={currency.name}
              appId={currency.appId}
              imageId={currency.imageId}
            />
          )
        })}
      </div>
    </div>
  )
}

export default Currencies
