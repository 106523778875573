import { action, computed, observable } from 'mobx'

import * as R from 'ramda'

import Storage from '../common/storage'

import API from '../common/api'
import logger from 'lib/logger'

import { setDefaultRequestHeader, formatAuthorizationHeader } from '../common/utils'

import { LoginResponse } from '../common/api/auth/login'

const StorageKey = {
  ACCESS_TOKEN: 'accessToken',
  REFRESH_TOKEN: 'refreshToken',
  BE_VERSION: 'version',
}

enum AuthStates {
  UNAUTHENTICATED = 'unauthenticated',
  IN_PROGRESS = 'inProgress',
  AUTHENTICATED = 'authenticated',
}

class AuthStore {
  @observable
  authState: Nullable<AuthStates> = null

  get refreshToken() {
    return Storage.get(StorageKey.REFRESH_TOKEN)
  }

  @computed
  get isAuthenticated(): boolean {
    return this.authState === AuthStates.AUTHENTICATED
  }

  @computed
  get isAuthenticating() {
    return this.authState === AuthStates.IN_PROGRESS || R.isNil(this.authState)
  }

  @action
  async authorize({ force = false } = {}) {
    if (!force && this.authState === AuthStates.AUTHENTICATED) {
      return
    }

    const refreshToken = this.refreshToken

    if (!refreshToken) {
      this.cleanup()

      return
    }

    this.authState = AuthStates.IN_PROGRESS

    try {
      const result = await API.token.refresh({ refreshToken })

      this.handleTokenResult(result)

      this.authState = AuthStates.AUTHENTICATED
    } catch (e) {
      logger.error(e)

      this.cleanup()
    }
  }

  @action
  async login(data: LoginResponse) {
    this.handleTokenResult(data)

    this.authState = AuthStates.AUTHENTICATED

    Storage.setBeVersion(StorageKey.BE_VERSION, data.backendVersion)
  }

  @action
  async logout() {
    if (!this.isAuthenticated) {
      return
    }

    this.cleanup()
  }

  @action
  cleanup() {
    Storage.delete(StorageKey.REFRESH_TOKEN)

    setDefaultRequestHeader('Authorization', null)

    this.authState = AuthStates.UNAUTHENTICATED
  }

  @action
  handleTokenResult({ credentials: { accessToken, refreshToken } }: LoginResponse) {
    setDefaultRequestHeader('Authorization', formatAuthorizationHeader(accessToken))
    Storage.set(StorageKey.ACCESS_TOKEN, accessToken)
    Storage.set(StorageKey.REFRESH_TOKEN, refreshToken)
  }
}

export default AuthStore
