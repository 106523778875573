import { observer } from 'mobx-react'

import styles from './ActivityLogTable.module.scss'

const ActivityLogTable = () => {
  return (
    <div className={styles.container}>
      <h1>This page is currently under construction</h1>
    </div>
  )
}

export default observer(ActivityLogTable)
