import { observer } from 'mobx-react'
import { thread } from 'lib/fn/thread'

import TracksForStyngsStore from '../TracksForStyngsStore'
import TracksForStyngs from '../TracksForStyngs'

const TracksForStynsPage = () => {
  const tracksStore = new TracksForStyngsStore()

  return <TracksForStyngs tracksStore={tracksStore} />
}

export default thread(TracksForStynsPage, [observer])
