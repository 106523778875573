import Route, { ParametrizedRoute } from 'lib/routing/Route'
import CurrenciesPage from './CurrenciesPage'
import CreateCurrencyPage from './CreateCurrencyPage'
import EditCurrencyPage from './EditCurrencyPage'
import strings from '../../locale/strings'

export const CurrenciesRoute: Route = {
  path: '/currencies',
  name: strings.navigation.currencies,
  component: CurrenciesPage,
}

export const CreateCurrencyRoute: Route = {
  path: '/currencies/create',
  name: strings.navigation.createCurrency,
  exact: true,
  component: CreateCurrencyPage,
}

export const EditCurrencyRoute: ParametrizedRoute = {
  path: '/currencies/edit/:currencyId',
  name: strings.navigation.createCurrency,
  exact: true,
  component: EditCurrencyPage,
  getUrl: ({ currencyId }) => `/currencies/edit/${currencyId}`,
}
