import React from 'react'
import * as R from 'ramda'
import cx from 'classnames'

import styles from './infoContainer.module.scss'

interface RadioProps {
  label: string
  value?: string | string[] | number | number[]
  children?:  React.ReactNode
  className?: string
}

const InfoContainer = ({
  label,
  value,
  children,
  className = '',
}: RadioProps) => {
  const containerStyles = cx(styles.container, className)

  return (
    <div className={containerStyles}>
      <h4 className={styles.title}>
        {label}
      </h4>
      <If condition={!R.isNil(value)}>
        <h5 className={styles.value}>
          {value}
        </h5>
      </If>
      {children}
    </div>
  )
}

export default InfoContainer
