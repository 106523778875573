import AbstractFetchStore from '../common/AbstractFetchStore'

import API from '../common/api'

import Storage from '../common/storage'
import { computed } from 'mobx'
import { StoreState } from '../common/constants'
import { RegisterResponse } from '../common/api/device/register'

const DEVICE_ID = 'deviceId'

class DeviceStore extends AbstractFetchStore<RegisterResponse> {
  constructor() {
    super(API.device.register)

    const deviceId = Storage.get(DEVICE_ID)

    if (!deviceId) {
      // noinspection JSIgnoredPromiseFromCall
      this.updateDeviceId()
    } else {
      this.setData({ deviceId })
      this.setState(StoreState.DONE)
    }
  }

  @computed
  get deviceId(): string {
    return this.data!.deviceId
  }

  updateDeviceId = async () => {
    const data = {
      platform: 'Web',
    }

    const responseDevice: RegisterResponse = await this.loadData(data)

    if (responseDevice.deviceId) {
      Storage.set(DEVICE_ID, responseDevice.deviceId)
    }
  }
}

export default DeviceStore
