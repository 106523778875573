import Route from 'lib/routing/Route'
import strings from '../../locale/strings'
import NftsPage from './NftsPage'

export const NftsRoute: Route = {
  path: '/nfts',
  name: strings.navigation.nfts,
  exact: true,
  component: NftsPage,
}
