import React from 'react'
import { thread } from 'lib/fn/thread'
import { observer } from 'mobx-react'
import { useNftsQuery } from '../NftsStore'

import Nfts from '../../nfts/Nfts'

const NftsPage: React.FC = () => {
  const { data, status, refetch } = useNftsQuery()

  const nfts = data?.items ?? []
  const pagesCount = data?.pagesCount ?? 1

  return <Nfts nfts={nfts} pagesCount={pagesCount} refetch={refetch} status={status} />
}

export default thread(NftsPage, [observer])
