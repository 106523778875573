import { useQuery } from 'react-query'

import PaginationStore from 'common/PaginationStore'
import API from 'common/api'
import { GenresResponse } from 'common/api/genre/genre'

class GenresStore extends PaginationStore {}

export const useGenresStyngsQuery = () => {
  return useQuery<GenresResponse>(
    ['genres-for-styngs'],
    () => {
      return API.genres.listForStyngs()
    },
    {
      retry: 1,
      keepPreviousData: true,
    },
  )
}

export default GenresStore
