import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { Redirect } from 'react-router'

import LoadingComponent from '../ui/Loading/LoadingPage'
import useStores from '../common/hook/useStore'
import { LoginRoute } from './pages/Login'

const AuthHOC = (Component: React.FC) => {
  const Auth: React.FC = ({ children }) => {
    const { authStore } = useStores()

    useEffect(() => {
      authStore.authorize()
    }, [authStore])

    return (
      <Choose>
        <When condition={authStore.isAuthenticated}>
          <Component>{children}</Component>
        </When>
        <When condition={authStore.isAuthenticating}>
          <LoadingComponent />
        </When>
        <Otherwise>
          <Redirect to={LoginRoute.path} />
        </Otherwise>
      </Choose>
    )
  }

  return observer(Auth)
}

export default AuthHOC
