import Route from 'lib/routing/Route'
import TracksForRadioPage from './TracksForRadioPage'
import strings from '../../locale/strings'

export const TracksForRadioRoute: Route = {
  path: '/tracks',
  name: strings.navigation.tracksForRadio,
  exact: true,
  component: TracksForRadioPage,
}
