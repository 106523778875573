import { useMutation } from 'react-query'

import API from '../../common/api'
import useStores from '../../common/hook/useStore'

import { ActivateStyng } from 'common/api/styng/styng'

export const useStyngActivate = (onSuccess: any) => {
  const { notificationsStore } = useStores()

  return useMutation<null, Error, ActivateStyng>(
    (id) => {
      return API.styngs.activate(id)
    },
    {
      onSuccess: (data) => {
        if (onSuccess) {
          onSuccess(data)
        }
      },
      onError: (error: any) => {
        notificationsStore.errorNotification(error)
      },
    },
  )
}
