import { action, observable } from 'mobx'
import { useQuery } from 'react-query'
import * as R from 'ramda'

import { Company } from 'common/api/company/company'
import API from 'common/api/index'
import PaginationStore from 'common/PaginationStore'
import { EditUserEntity, UserResponse } from 'common/api/user/user'
import useStores from 'common/hook/useStore'

class UserStore extends PaginationStore {
  @observable
  name: string = ''

  @observable
  id: string = ''

  @observable
  companies: Company[] = []

  @observable
  currentCompany: Nullable<Company> = null

  @action
  handleNameSearch = (filter: string) => {
    this.name = filter?.trim()
    this.changePage()
  }

  @action
  handleIdSearch = (filter: string) => {
    this.id = filter?.trim()
    this.changePage()
  }

  @action
  addCompany = (company: Company) => {
    if (!R.find(R.propEq('id', company.id), this.companies)) {
      this.companies.push(company)
    }
  }

  @action
  deleteCompany = (id: string) => {
    this.companies = R.reject(R.propEq('id', id), this.companies)
  }

  @action
  setCurrentCompany = (company: Nullable<Company>) => {
    this.currentCompany = company
  }

  @action
  setCompanies = (companies: Company[]) => {
    this.companies = companies
  }
}

export const useUsersQuery = (filterStore: UserStore) => {
  const { pageSize, page, name, id } = filterStore

  return useQuery<UserResponse>(
    ['users', pageSize, page, name, id],
    () => {
      return API.user.list({
        page: page,
        size: pageSize,
        name,
        id,
      })
    },
    {
      keepPreviousData: false,
      retry: 1,
      refetchOnWindowFocus: false,
    },
  )
}

export const useUserQuery = (userParam: Nullable<string> = null) => {
  const { applicationStore } = useStores()
  const { userId: userStore } = applicationStore
  const userId = userParam ?? userStore

  return useQuery<EditUserEntity>(
    ['user', userId],
    () => {
      return (
        API.user.get({
          userId,
        }) ?? []
      )
    },
    {
      keepPreviousData: true,
      retry: 1,
    },
  )
}

export default UserStore
