import { observer } from 'mobx-react'
import { thread } from 'lib/fn/thread'

import PlaylistsStore from '../PlaylistsStore'

import PlaylistsTable from '../pages/PlaylistsTable/PlaylistsTable'

const PlaylistsPage = () => {
  const playlistsStore = new PlaylistsStore()

  return <PlaylistsTable playlistsStore={playlistsStore} />
}

export default thread(PlaylistsPage, [observer])
