import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import styles from './spinner.module.scss'

const Spinner = () => {
  return (
    <div className={styles.holder}>
      <CircularProgress />
    </div>
  )
}

export default Spinner
