import React, { useEffect, useState } from 'react'

import { useAppsQuery } from 'apps/ApplicationStore'

import { Box, Autocomplete, Checkbox, Chip, FormControl, Switch, TextField as MuiTextField } from '@mui/material'

import Spinner from 'ui/Spinner/Spinner'

import useStores from 'common/hook/useStore'
import { Application } from 'common/api/app/app'
import { ApiStatuses } from 'common/constants'

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

import styles from './BindApplications.module.scss'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

interface BindApplicationsProps {
  selectedApps: Application[]
  handleChangeApps: (event: React.SyntheticEvent, value: any) => void
  handleDeleteApp: (optionId: string) => void
  withPublish?: boolean
  handleAppPublishToggle?: (app: any) => void
}

const BindApplications = ({
  selectedApps,
  handleChangeApps,
  handleDeleteApp,
  withPublish,
  handleAppPublishToggle,
}: BindApplicationsProps) => {
  const { applicationStore } = useStores()
  const { data: apps, status: appsStatus } = useAppsQuery(applicationStore)
  const [allApps, setAllApps] = useState<Application[]>([])
  const [loadingData, setLoadingData] = useState<boolean>(false)

  const [textValue, setTextValue] = useState<string>('')
  const [debouncedAppNameValue, setDebouncedAppNameValue] = useState<string>('')

  useEffect(() => {
    if (apps?.apps) {
      setAllApps(apps?.apps ?? [])
    }
  }, [apps])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedAppNameValue(textValue)
    }, 500)

    return () => clearTimeout(timeoutId)
  }, [textValue])

  useEffect(() => {
    if (applicationStore.handleNameSearch) {
      applicationStore?.handleNameSearch(debouncedAppNameValue)
      setLoadingData(false)
    }
  }, [debouncedAppNameValue])

  return (
    <FormControl fullWidth className={`${withPublish ? styles.autocompleteWithSwitch : styles.customSelect}`}>
      <Autocomplete
        freeSolo
        multiple
        disableCloseOnSelect
        data-test="available-apps-field"
        id="availableApps"
        className={styles.autocompleteField}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        value={selectedApps}
        inputValue={textValue || ''}
        options={allApps}
        noOptionsText={''}
        getOptionLabel={(option: Application) => option.name}
        renderOption={(props, option, { selected }) => (
          <li {...props} key={option.id}>
            <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
            {option.name}
          </li>
        )}
        renderTags={() => null}
        renderInput={(params) => (
          <MuiTextField
            {...params}
            label="Choose applications"
            placeholder="Search"
            onChange={(e) => {
              setAllApps([])
              setLoadingData(true)
              setTextValue(e.target.value)
            }}
          />
        )}
        onChange={handleChangeApps}
        onClose={() => {
          setLoadingData(false)
          setTextValue('')
        }}
      />
      {appsStatus === ApiStatuses.LOADING || loadingData ? (
        <Box className={styles.autocompleteSpinnerWrapper}>
          <Spinner />
        </Box>
      ) : (
        <React.Fragment>
          {selectedApps.length > 0 && (
            <Box className={styles.autocompleteFieldValues}>
              {selectedApps.map((option: any) => (
                <div className={styles.autocompleteFieldWrapper} key={option.id}>
                  <Chip
                    className={styles.autocompleteFieldValue}
                    label={option.name}
                    onDelete={() => handleDeleteApp(option.id)}
                  />
                  {withPublish && (
                    <Switch
                      size="small"
                      className={styles.autocompleteFieldSwitch}
                      checked={option.published === true}
                      onChange={handleAppPublishToggle ? () => handleAppPublishToggle(option) : () => {}}
                    />
                  )}
                </div>
              ))}
            </Box>
          )}
        </React.Fragment>
      )}
    </FormControl>
  )
}

export default BindApplications
