/* eslint-disable react/jsx-sort-props */
import React from 'react'
import { Duration } from 'luxon'
import { usePlaylistDmcaQuery } from '../../PlaylistsStore'
import Modal from '@mui/material/Modal'
import Spinner from 'ui/Spinner/Spinner'
import Text from 'locale/strings'
import Button from 'ui/Button/Button'
import { ButtonSize } from 'ui/Button/Button'

import styles from './dmcaCheckModal.module.scss'

interface DmcaCheckModalProps {
  open: boolean
  playlistId: string
  playlistName: string
  numOfTracks: string
  handleClose: () => void
}

const DmcaCheckModal = ({ open, playlistId, playlistName, numOfTracks, handleClose }: DmcaCheckModalProps) => {
  const { data, status } = usePlaylistDmcaQuery(playlistId)
  const dmca = data?.playlistTerritoriesDurationLengthViolation ?? []

  const renderContentBasedOnCompliance = () => {
    if (dmca.length === 0 && parseInt(numOfTracks) !== 0) {
      return <div className={styles.regularInfo}>{Text.dmcModal.rulesAreMet}</div>
    } else if (dmca.length === 0 && parseInt(numOfTracks) === 0) {
      return <div className={styles.errorInfo}>{Text.dmcModal.noSongs}</div>
    } else if (dmca.length !== 0) {
      return (
        <React.Fragment>
          <div className={styles.errorInfo}>
            <p>{Text.dmcModal.rulesNotMetPartOne}</p>
            <p>{Text.dmcModal.rulesNotMetPartTwo}</p>
          </div>

          <div className={styles.errorTable}>
            <div className={styles.errorTableRow}>
              <p className={styles.errorTableColumn}>{Text.dmcModal.countries}</p>
              <p className={styles.errorTableColumn}>{Text.dmcModal.playlistDuration}</p>
              <p className={styles.errorTableColumn}>{Text.dmcModal.timeToAdd}</p>
            </div>
            {dmca.map((e, i) => {
              return (
                <div key={i} className={styles.errorTableRow}>
                  <p className={styles.errorTableColumn}>{e.territoryCode}</p>
                  <p className={styles.errorTableColumn}>{Duration.fromISO(e.duration).toFormat('hh:mm:ss')}</p>
                  <p className={styles.errorTableColumn}>
                    {Duration.fromISO(e.durationToBeAdded).toFormat('hh:mm:ss')}
                  </p>
                </div>
              )
            })}
          </div>
        </React.Fragment>
      )
    }
  }

  return (
    <React.Fragment>
      <Modal
        onBackdropClick={handleClose}
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className={styles.container}>
          {status === 'loading' ? (
            <Spinner />
          ) : (
            <React.Fragment>
              <div className={styles.header}>{Text.dmcModal.title}</div>

              <div className={styles.generalInfo}>
                <p className={styles.text}>{Text.dmcModal.subTitle}</p>
                <p>{playlistName}</p>
              </div>

              {renderContentBasedOnCompliance()}

              <div className={styles.buttonContainer}>
                <Button size={ButtonSize.SMALL} onClick={handleClose}>
                  OK
                </Button>
              </div>
            </React.Fragment>
          )}
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default DmcaCheckModal
