import { useState } from 'react'
import * as R from 'ramda'
import { useMutation } from 'react-query'
import { ApiError } from 'lib/api/error'

import { Track, TrackDownloadResponse, TrackDownloadRequest } from '../../../common/api/track/track'
import Text from '../../../locale/strings'
import Tooltip from '../../../ui/Tooltip/Tooltip'
import API from '../../../common/api'
import { ErrorCode } from '../../../common/errors'

import styles from './trackItem.module.scss'

interface TrackItemProps {
  track: Track
  onClick: (track: Track) => void
  active?: boolean
}

const TrackItem = ({ track, onClick, active = false }: TrackItemProps) => {
  const [open, setOpen] = useState(false)
  const [isDownload, setIsDownload] = useState(true)

  const handleClick = () => {
    onClick(track)
  }

  const mutation = useMutation<TrackDownloadResponse, ApiError, TrackDownloadRequest>(
    async (body: TrackDownloadRequest) => {
      return API.track.download(body)
    },
    {
      onSuccess: (data) => {
        window.open(data.url, '_blank')
      },
      onError: (error) => {
        setIsDownload(false)

        if (error.errorCode === ErrorCode.TRACK_WITHDRAWN) {
          setOpen(true)

          setTimeout(() => {
            setOpen(false)
          }, 1000)
        }
      },
    },
  )

  const handleDownload = () => {
    mutation.mutate({ trackId: track.trackId })
  }

  return (
    <div className={styles.container} onClick={handleClick}>
      <div className={styles.picture}>
        <If condition={!R.isNil(track.imageUrl)}>
          <img src={track.imageUrl} alt="pictureTrack" loading="lazy" />
        </If>
      </div>
      <h4>{track.title}</h4>
      <If condition={active}>
        <Tooltip title={Text.page.styngs.create.withdrawnMessage} open={open}>
          <Choose>
            <When condition={isDownload}>
              <div className={styles.download} onClick={handleDownload}>
                {Text.common.download}
              </div>
            </When>
            <Otherwise>
              <div className={styles.outlined}>{Text.page.styngs.create.unavailable}</div>
            </Otherwise>
          </Choose>
        </Tooltip>
      </If>
    </div>
  )
}

export default TrackItem
