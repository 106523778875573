/* eslint-disable @typescript-eslint/indent */
import React, { Dispatch, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import { InputLabel, MenuItem, Select, FormControl } from '@mui/material'
import LoadingComponent from '../../ui/Loading/LoadingPage'

import Text from '../../locale/strings'

import { AppFeaturesRender, AppFeatures } from '../../common/api/app/app'

// features query
import { useFeaturesQuery } from '../ApplicationStore'
import styles from './features.module.scss'

interface FeaturesProps {
  setNewFeatures: Dispatch<React.SetStateAction<AppFeatures[]>>
  features?: AppFeatures[]
}

const Features = ({ features, setNewFeatures }: FeaturesProps) => {
  const [selectedFeaturesTypes, setSelectedFeaturesTypes] = useState<AppFeatures[]>([])
  // get features
  const { data: featuresOptions, status: featureStatus } = useFeaturesQuery()

  useEffect(() => {
    let initialValue: AppFeatures[] = []

    if (features !== undefined) {
      initialValue = features
      setSelectedFeaturesTypes((prevState) => [...prevState, ...initialValue])
    }
  }, [])

  const handleChange = (selectedFeature: any, featureName: string) => {
    setSelectedFeaturesTypes((prevArray: AppFeatures[]) => {
      // Check if the objectId already exists in the array
      const objectIndex = prevArray.findIndex((obj) => obj.name === featureName)

      if (objectIndex !== -1) {
        // If name exists, update the enabledFor
        return prevArray.map((obj) => (obj.name === featureName ? { ...obj, enabledFor: selectedFeature } : obj))
      }

      // If name doesn't exist, add a new object to the array
      return [...prevArray, { name: featureName, enabledFor: selectedFeature }]
    })
  }

  const renderFeatures = () => {
    return featuresOptions.features.map((feature: AppFeaturesRender, index: number) => (
      <FormControl fullWidth key={index} sx={{ mt: 1, mb: 1 }}>
        <InputLabel>{feature.name}</InputLabel>
        <Select
          multiple
          data-test="features-field"
          label={feature.name}
          value={
            selectedFeaturesTypes.findIndex((obj: AppFeatures) => obj['name'] === feature.name) !== -1
              ? selectedFeaturesTypes[
                  selectedFeaturesTypes.findIndex((obj: AppFeatures) => obj['name'] === feature.name)
                ].enabledFor
              : []
          }
          onChange={(e) => handleChange(e.target.value, feature.name)}
        >
          {feature.subscriptionTypes.map((name) => (
            <MenuItem key={name} value={name}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    ))
  }

  useEffect(() => {
    setNewFeatures(selectedFeaturesTypes.filter((feature) => feature.enabledFor.length > 0))
  }, [selectedFeaturesTypes])

  return (
    <Box>
      <div className={styles.container}>
        <div className={styles.form}>
          <Choose>
            <When condition={featureStatus === 'loading'}>
              <LoadingComponent />
            </When>
            <When condition={featureStatus === 'success'}>{renderFeatures()}</When>
            <When condition={featureStatus === 'error'}>
              <div>{Text.errors.generalErrorMessage}</div>
            </When>
          </Choose>
        </div>
      </div>
    </Box>
  )
}

export default Features
