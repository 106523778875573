import React from 'react'
import { observer } from 'mobx-react'

import { thread } from 'lib/fn/thread'
import PermissionValidationHOC from '../../user/PermissionValidationHOC'
import { Permission } from '../../common/constants'
import EditAppEvent from '../../appEvent/EditAppEvent/EditAppEvent'

const CreateAppEventPage = () => {
  return <EditAppEvent />
}

export default thread(CreateAppEventPage, [observer, PermissionValidationHOC(Permission.CREATE_STYNG)])
