import { observer } from 'mobx-react'
import { useParams } from 'react-router-dom'
import * as R from 'ramda'

import { thread } from 'lib/fn/thread'
import useStores from '../../common/hook/useStore'
import LoadingRemote from '../../common/LoadingRemote'
import { useCurrencyQuery } from '../../currency/hooks'
import EditCurrency from '../../currency/EditCurrency/EditCurrency'
import { CurrenciesRoute } from '.'

interface ParamProps {
  currencyId?: string
}

const EditCurrencyPage = () => {
  const { navigationStore } = useStores()
  const { currencyId = '' } = useParams<ParamProps>()
  const { data, status } = useCurrencyQuery(Number(currencyId))

  if (R.isEmpty(currencyId)) {
    navigationStore.goToPage(CurrenciesRoute.path)
  }

  return (
    <LoadingRemote status={status}>
      <EditCurrency initialCurrency={data} />
    </LoadingRemote>
  )
}

export default thread(EditCurrencyPage, [observer])
