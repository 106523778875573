import { observer } from 'mobx-react'
import { thread } from 'lib/fn/thread'

import ReportsTable from './ReportsTable/ReportsTable'

const Reports = () => {
  return <ReportsTable />
}

export default thread(Reports, [observer])
