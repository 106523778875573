import { observer } from 'mobx-react'
import { thread } from 'lib/fn/thread'

import StynglistsTable from '../pages/StynglistsTable/StynglistsTable'

const StynglistsPage = () => {
  return <StynglistsTable />
}

export default thread(StynglistsPage, [observer])
