import Chip from 'ui/Chip/Chip'

const CompanyTableInfo = (props: any) => {
  return (
    <div>
      {Object.values(props).map(
        (item, i) =>
          item !== undefined &&
          (item === 'No available apps for this company' ? (
            <div style={{ margin: 10 }}> No available apps for this company </div>
          ) : (
            <Chip label={item} key={i} />
          )),
      )}
    </div>
  )
}

export default CompanyTableInfo
