/* eslint-disable jsx-control-statements/jsx-for-require-each */
import React from 'react'
import { observer } from 'mobx-react'

import { thread } from 'lib/fn/thread'

import StyngResult from './StyngResult/StyngResult'
import { Styng } from '../../common/api/styng/styng'
import StyngsHOC from '../../styngs/StyngsHOC'
import styles from './styngsFilter.module.scss'

interface StyngsFilterProps {
  styngs?: Styng[]
  onClickStyng: (styng: Styng) => void
}

const StyngsFilter = ({ styngs, onClickStyng }: StyngsFilterProps) => {
  return (
    <React.Fragment>
      {styngs && styngs.length > 0 && (
        <div className={styles.styngFilter}>
          <For
            of={styngs}
            body={(styng: Styng) => <StyngResult key={styng.id} styng={styng} onClick={onClickStyng} />}
          />
        </div>
      )}
    </React.Fragment>
  )
}

export default thread(StyngsFilter, [observer, StyngsHOC])
