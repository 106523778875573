import React, { useEffect } from 'react'
import * as R from 'ramda'
import { useObserver } from 'mobx-react'

import LoadingComponent from '../ui/Loading/LoadingPage'
import useStores from '../common/hook/useStore'

const UserProfileHOC = (Component: React.FC) => {
  const UserProfile: React.FC = ({ children }) => {
    const { userStore } = useStores()

    useEffect(() => {
      userStore.loadData({})
    }, [userStore])

    return useObserver(() => (
      <Choose>
        <When condition={R.isNil(userStore.data)}>
          <LoadingComponent />
        </When>
        <Otherwise>
          <Component>{children}</Component>
        </Otherwise>
      </Choose>
    ))
  }

  return UserProfile
}

export default UserProfileHOC
