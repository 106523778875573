import { action, observable } from 'mobx'
import useStores from '../common/hook/useStore'
import { useQuery } from 'react-query'
import API from '../common/api'
import { StyngsResponse, RoyaltyFreeStyngsResponse } from '../common/api/styng/styng'
import PaginationStore from '../common/PaginationStore'

class StyngsStore extends PaginationStore {
  @observable
  isrc: string = ''
  @observable
  name: string = ''
  @observable
  artist: string = ''
  @observable
  songName: string = ''
  @observable
  genreIds: string = ''
  @observable
  published: boolean | null = null

  @action
  handleIsrcSearch = (filter: string) => {
    this.isrc = filter?.trim()
    this.changePage()
  }

  @action
  handleNameSearch = (filter: string) => {
    this.name = filter?.trim()
    this.changePage()
  }

  @action
  handleArtistSearch = (filter: string) => {
    this.artist = filter?.trim()
    this.changePage()
  }

  @action
  handleSongNameSearch = (filter: string) => {
    this.songName = filter?.trim()
    this.changePage()
  }

  @action
  handleGenreSearch = (filter: []) => {
    this.genreIds = filter.toString()
    this.changePage()
  }

  @action
  handlePublishSearch = (filter: boolean | null) => {
    this.published = filter
    this.changePage()
  }
}

export const useStyngsQuery = (filterStore: StyngsStore) => {
  const { pageSize, page, isrc, name, artist, songName, genreIds, published } = filterStore

  return useQuery<StyngsResponse>(
    ['lsr-styngs', pageSize, page, isrc, name, artist, songName, genreIds, published],
    () => {
      return API.styngs.list({
        page,
        size: pageSize,
        isrc,
        name,
        artistName: artist,
        trackName: songName,
        genreIds,
        activeOnly: published,
      })
    },
    {
      retry: 3,
      keepPreviousData: true,
    },
  )
}

export const useRoyaltyFreeStyngsQuery = () => {
  const { styngsStore } = useStores()

  const { pageSize, page, filter } = styngsStore

  return useQuery<RoyaltyFreeStyngsResponse>(
    ['royalty-free-styngs', pageSize, page, filter],
    () => {
      return API.styngs.listRoyaltyFree({
        page,
        size: pageSize,
        name: filter,
      })
    },
    {
      retry: 3,
      keepPreviousData: true,
    },
  )
}

export const useStyngSourceTypesQuery = () => {
  return useQuery<any>(
    ['styng-source-types'],
    () => {
      return API.styngs.getStyngSourceTypes() ?? []
    },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      retry: 1,
    },
  )
}

export default StyngsStore
