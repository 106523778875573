import ReactSelect, { MultiValue } from 'react-select'
import * as R from 'ramda'
import cx from 'classnames'

import { Identity, OptionType } from 'common/api/common/common'

import { useState } from 'react'
import { InputActionMeta } from 'react-select/dist/declarations/src/types'

import styles from './searchSelect.module.scss'

interface SearchSelectProps<ValueType = string> {
  value: ValueType[]
  options: OptionType<ValueType>[]
  onChange: (value: ValueType[]) => void
  label?: string
  isLoading?: boolean
  onInputChange?: (value: string) => void
  onBlur?: () => void
  onFocus?: () => void
  disabled?: boolean
  className?: string
  blurInputOnSelect?: boolean
  closeMenuOnSelect?: boolean
}

const SearchSelect = <ValueType extends Identity>(props: SearchSelectProps<ValueType>) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const [inputValue, setInputValue] = useState('')

  const handleChange = (option: MultiValue<OptionType<ValueType>>) => {
    const newValues = R.map(R.prop('value'), option)

    if (!R.isEmpty(newValues)) {
      props.onChange?.(newValues)
    }
  }

  const { label, className, options, blurInputOnSelect, isLoading } = props

  const handleInputChange = async (value: string, { action }: InputActionMeta) => {
    if (action !== 'input-change') {
      return false
    }

    setInputValue(value)

    props.onInputChange?.(value)
  }

  const handleFocus = () => {
    setMenuIsOpen(true)

    props.onFocus?.()
  }

  const handleBlur = () => {
    setMenuIsOpen(false)

    props.onBlur?.()
  }

  return (
    <div className={cx(styles.container)}>
      <If condition={!R.isNil(label)}>
        <h5 className={styles.label}>{label}</h5>
      </If>
      <ReactSelect<OptionType<ValueType>, true>
        isSearchable
        isMulti
        isLoading={isLoading}
        className={className}
        value={[]}
        options={options}
        inputValue={inputValue}
        menuIsOpen={menuIsOpen}
        blurInputOnSelect={blurInputOnSelect}
        onChange={handleChange}
        onInputChange={handleInputChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
    </div>
  )
}

export default SearchSelect
