import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'

import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import QueueMusicIcon from '@mui/icons-material/QueueMusic'
import Paper from '@mui/material/Paper'

import useStores from '../../common/hook/useStore'
import { AppsRoute } from '../../apps/pages'
import { Duration, DateTime } from 'luxon'
import { Playlist } from 'common/api/playlist/playlist'
import Text from '../../locale/strings'

import Button from '../../ui/Button/Button'
import Table from '../../ui/Table/Table'

interface AppInfoProps {
  app: any
  playlists: Playlist[]
}

const AppInfo = ({ app, playlists }: AppInfoProps) => {
  const { navigationStore } = useStores()
  const [value, setValue] = useState('1')

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  const columns = ['Name', 'Duration', 'Number of Tracks', 'Date and time published']

  const dataTable = playlists.map((playlist: Playlist) => [
    playlist.name,
    Duration.fromISO(playlist.duration).toFormat('hh:mm:ss'),
    playlist.numberOfTracks,
    playlist.publishedAt === null ? 'not published' : DateTime.fromISO(playlist.publishedAt).toFormat('ff'),
  ])

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <Toolbar sx={{ backgroundColor: '#1976d2' }}>
        <Typography sx={{ ml: 2, flex: 1, color: '#fff' }} variant="h6" component="div">
          {app.name}
        </Typography>
      </Toolbar>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList aria-label="app-tabs" onChange={handleChange}>
            <Tab
              value="1"
              icon={<QueueMusicIcon fontSize="small" />}
              iconPosition="start"
              label={Text.page.app.radioPlaylists}
              sx={{ minHeight: '48px', height: '48px', textTransform: 'none' }}
            />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Paper elevation={3}>
            <Table
              hideActionColumn
              stickyHeader
              tableMaxHeight={window.innerHeight - 300}
              size="small"
              sx={{
                tableLayout: 'fixed',
              }}
              columns={columns}
              data={dataTable}
            />
          </Paper>
        </TabPanel>
      </TabContext>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', paddingRight: 40 }}>
        <Button onClick={() => navigationStore.goToPage(AppsRoute.path)}>Back</Button>
      </div>
    </Box>
  )
}

export default AppInfo
