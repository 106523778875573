import { observer } from 'mobx-react'

import { thread } from 'lib/fn/thread'
import EditCurrency from '../EditCurrency/EditCurrency'

const CreateCurrencyPage = () => {
  return <EditCurrency />
}

export default thread(CreateCurrencyPage, [observer])
