/* eslint-disable jsx-control-statements/jsx-for-require-each */
import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { Duration } from 'luxon'

import useStores from '../common/hook/useStore'

import Pagination from '../ui/Pagination/Pagination'
import SearchBar from '../ui/SearchBar/SearchBar'
import { Track, TrackDownloadValues } from '../common/api/track/track'
import TracksStore, { useTracksQuery } from './TracksStore'
import Table from '../ui/Table/Table'
import TrackModal from './TrackModal/TrackModal'
import DownloadTrackModal from './DownloadTrackModal/DownloadTrackModal'
import Spinner from '../ui/Spinner/Spinner'
import Text from '../locale/strings'
import TrackFilter from './TrackFilter/TrackFilter'

import styles from './tracks.module.scss'

interface TracksProps {
  tracksStore: TracksStore
}

const Tracks = ({ tracksStore }: TracksProps) => {
  const { notificationsStore } = useStores()
  const [filter, setFilter] = useState<string>('')
  const [showModal, setShowModal] = useState<boolean>(false)
  const [trackMediaNetId, setTrackMediaNetId] = useState<string>('')
  const { data, status } = useTracksQuery(tracksStore)

  const tracks = data?.tracks ?? []

  const [showDownloadTrackModal, setShowDownloadTrackModal] = useState<boolean>(false)

  const [dataForTrackDownloadModal, setDataForTrackDownloadModal] = useState<TrackDownloadValues>({
    cover: '',
    mediaNetId: '',
    artist: '',
    title: '',
  })

  const numberOfPages = data?.numberOfPages ?? 10

  const handleChangePage = (value: number) => {
    tracksStore.changePage(value)
  }

  const handleSearch = (value: string) => {
    tracksStore.search(value)
  }

  // Table data
  const columns = [
    'Cover',
    'MediaNet ID',
    'ISRC',
    'Song name',
    'Explicit',
    'Available countries',
    'Label',
    'Label owner',
    'Artist',
    'Album',
    'Genres',
    'Duration',
    'Actions',
  ]

  const dataTable = tracks.map((track: Track) => [
    track.imageUrl,
    track.trackId,
    track.isrc,
    track.title,
    track.parentalAdvisory ? 'Yes' : 'No',
    track.availableCountries.length === 0 ? '/' : track.availableCountries.map((e) => `${e} `),
    track.label,
    track.labelOwner,
    track.artist,
    track.albumName,
    track.genre,
    Duration.fromISO(track.duration).toFormat('mm:ss'),
  ])

  const handleEditClick = (id: string) => {
    const track = tracks.filter((item) => item.trackId === id)

    if (track[0].availableCountries.length === 0) {
      notificationsStore.infoNotification('This track is not available for adding to the playlist')

      return
    }

    setTrackMediaNetId(id)
    setShowModal(true)
  }

  const handleDownloadTrackClick = (id: string) => {
    const track = tracks.filter((track) => track.trackId === id)
    const currentTrack = track[0]

    const helperObjDataForTrackDownloadModal: TrackDownloadValues = {
      cover: currentTrack.imageUrl,
      mediaNetId: currentTrack.trackId,
      artist: currentTrack.artist,
      title: currentTrack.title,
    }

    setDataForTrackDownloadModal(helperObjDataForTrackDownloadModal)
    setShowDownloadTrackModal(true)
  }

  const handleCloseModal = () => {
    setTrackMediaNetId('')
    setShowModal(false)

    setDataForTrackDownloadModal({
      cover: '',
      mediaNetId: '',
      artist: '',
      title: '',
    })

    setShowDownloadTrackModal(false)
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <SearchBar
          hideResults
          label={Text.page.tracks.searchPlaceholder}
          filter={filter}
          onChangeFilter={setFilter}
          onSearch={handleSearch}
        />
      </div>
      <TrackFilter tracksStore={tracksStore} />
      {status === 'loading' ? (
        <Spinner />
      ) : (
        <React.Fragment>
          {tracks.length > 0 ? (
            <React.Fragment>
              <Table
                plusIcon
                hideDelete
                showDownloadTrack
                columns={columns}
                data={dataTable}
                onEditClick={handleEditClick}
                onDownloadFile={handleDownloadTrackClick}
              />
              <If condition={tracks.length > 0}>
                <div className={styles.paginationContainer}>
                  <div className={styles.pagination}>
                    <Pagination page={tracksStore.page} count={numberOfPages} onChange={handleChangePage} />
                  </div>
                </div>
              </If>
            </React.Fragment>
          ) : (
            <p>There are no results that match your search</p>
          )}
        </React.Fragment>
      )}

      <TrackModal open={showModal} handleClose={handleCloseModal} trackMediaNetId={trackMediaNetId} />
      <DownloadTrackModal
        open={showDownloadTrackModal}
        handleClose={handleCloseModal}
        dataForTrackDownloadModal={dataForTrackDownloadModal}
      />
    </div>
  )
}

export default observer(Tracks)
