import { action, computed, observable } from 'mobx'
import * as R from 'ramda'
import { useQuery } from 'react-query'

import { thread } from 'lib/fn/thread'
import AbstractFetchStore from '../common/AbstractFetchStore'
import API from '../common/api'
import { ProfileResponse, Role, User, UserResponse } from '../common/api/user/user'
import { Permission } from '../common/constants'
import rolePermissions from './rolePermission'
import { Application } from '../common/api/app/app'
import { Company } from '../common/api/company/company'
import useStores from '../common/hook/useStore'

const PROFILE_NOT_FOUND_CODE = 6

class UserStore extends AbstractFetchStore<ProfileResponse> {
  @observable
  users: User[] = []

  constructor() {
    super(API.user.profile)
  }

  @computed
  get hasNoProfile() {
    return this.errorCode === PROFILE_NOT_FOUND_CODE
  }

  getCompany(companyId: string): Nullable<Company> {
    return R.find(R.propEq('id', companyId), this.data!.user?.companies)
  }

  getFirstCompany(): Nullable<Company> {
    return this.data?.user?.companies[0]
  }

  getCompanies(): Company[] {
    return this.data?.user?.companies ?? []
  }

  getCompanyApp(companyId: string): Application[] {
    return this.getCompany(companyId)?.apps ?? []
  }

  getUserCredentials(): string {
    const user = this.data?.user

    if (user) {
      return `${user.firstName} ${user.lastName}`
    }

    return ''
  }

  getUserRoles(): Role[] {
    return this.data?.roles ?? [Role.CONTENT]
  }

  hasPermission = (permission: Permission) => {
    return thread(R.pick(this.getUserRoles(), rolePermissions), [R.values, R.flatten, R.includes(permission)])
  }

  @action
  setUsers(users: User[]) {
    this.users = users
  }

  getUser(id: string): Nullable<User> {
    const user: Nullable<User> = R.find(R.propEq('userId', id), this.users)

    if (!R.isNil(user)) {
      user.roles = []
    }

    return user
  }

  @computed
  get isAdmin(): boolean {
    return R.includes(Role.ADMIN, this.getUserRoles())
  }

  getProfile(): Nullable<User> {
    return this.data?.user
  }
}

export const useUserQuery = () => {
  const { userStore } = useStores()

  return useQuery<UserResponse>(
    ['users'],
    () => {
      return API.user.list({})
    },
    {
      retry: 0,
      keepPreviousData: true,
      onSuccess: (data) => {
        const user = userStore.getProfile()

        if (!R.isNil(user)) {
          userStore.setUsers([user])
        } else {
          userStore.setUsers([])
        }
      },
      onError: () => {
        userStore.setUsers([])
      },
    },
  )
}

export default UserStore
