import useStores from '../../../common/hook/useStore'

import Text from '../../../locale/strings'
import Button from '../../../ui/Button/Button'

import styles from './additionalTablePlaylistInfo.module.scss'

const AdditionTablePlaylistInfo = (props: any) => {
  const { notificationsStore } = useStores()
  const data = props[0]

  const handleCopyId = (value: string) => {
    notificationsStore.successNotification('Playlist id is copied')
    navigator.clipboard.writeText(value)
  }

  return (
    <div className={styles.container}>
      <div className={styles.infoContainer}>
        <h3 className={styles.title}>Playlist name: {data.name}</h3>
        <h4 className={styles.id}>Internal ID: {data.id}</h4>
        <div className={styles.btnContainer}>
          <Button className={styles.buttons} onClick={() => handleCopyId(data.id)}>
            {'Copy ' + Text.common.id}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default AdditionTablePlaylistInfo
