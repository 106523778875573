import { TextField as MuiTextField } from '@mui/material'
import React, { ChangeEvent } from 'react'
import cx from 'classnames'
import * as R from 'ramda'

import styles from './textField.module.scss'

interface TextFieldProps {
  value: Nullable<string | number>
  required?: boolean
  fullWidth?: boolean
  autoFocus?: boolean
  className?: string
  id?: string
  name?: string
  label?: string
  placeholder?: string
  type?: string
  autoComplete?: string
  readOnly?: boolean
  disabled?: boolean
  multiline?: boolean
  rows?: number
  error?: string
  size?: string
  InputProps?: any
  inputProps?: any
  sx?: any
  adornment?: React.ReactNode
  onPaste?: (event: React.ClipboardEvent) => void
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  onBlur?: (e: any) => void
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void
}

const TextField: React.FC<TextFieldProps> = ({
  value,
  error,
  name,
  className = '',
  label = undefined,
  placeholder = '',
  fullWidth = false,
  required = false,
  autoFocus = false,
  id,
  type,
  autoComplete,
  readOnly = false,
  disabled = false,
  multiline = false,
  size = '',
  rows,
  InputProps,
  inputProps,
  sx,
  adornment,
  onPaste,
  onChange,
  onBlur,
  onKeyPress,
}) => (
  <MuiTextField
    error={!R.isEmpty(error) && !R.isNil(error)}
    rows={rows}
    helperText={error}
    multiline={multiline}
    required={required}
    fullWidth={fullWidth}
    autoFocus={autoFocus}
    variant="outlined"
    size={size === 'small' ? 'small' : 'medium'}
    margin="normal"
    id={id}
    className={cx(styles.input, className)}
    name={name}
    label={label}
    placeholder={placeholder}
    type={type}
    autoComplete={autoComplete}
    value={value}
    disabled={disabled}
    inputProps={inputProps}
    InputProps={{
      ...InputProps,
      readOnly: readOnly,
      endAdornment: adornment,
    }}
    sx={sx}
    onPaste={onPaste}
    onChange={onChange}
    onBlur={onBlur}
    onKeyPress={onKeyPress}
  />
)

export default TextField
