import { observer } from 'mobx-react'
import { thread } from 'lib/fn/thread'

import ActivityLogTable from './ActivityLogTable/ActivityLogTable'

const ActivityLog = () => {
  return <ActivityLogTable />
}

export default thread(ActivityLog, [observer])
