import App from './App'
import { Redirect } from 'react-router-dom'

import { BASE_PATH } from './common/constants'
import Route from 'lib/routing/Route'
import Router from 'lib/routing/Router'
import { LoginRoute } from './auth/pages/Login'

import { MainRoute } from './main'
import { PlaylistsRoute, PlaylistsCreateRoute, PlaylistEditRoute } from './playlists'
import { PlatformsRoute, PlatformCreateRoute, PlatformEditRoute } from './platforms'
import { CompaniesRoute, CompaniesCreateRoute, CompaniesEditRoute } from './companies'
import { StynglistsRoute, CreateStynglistRoute, EditStynglistRoute } from './stynglists'
import { SoundboardRoute, CreateSoundboardRoute, EditSoundboardRoute } from './soundboard'
import { UsersRoute, UserCreateRoute, UserEditRoute } from './users'
import { StyngsRoute, CreateRFStyngRoute, EditRFStyngRoute } from './styngs'
import MainLayout from './layouts/Main/MainLayout'
import { AppEventsRoute, CreateAppEventRoute, EditAppEventRoute } from './appEvent/pages'
import { AppsRoute, AppInfoRoute, AppGeneralFormNewRoute, AppGeneralFormEditRoute } from './apps/pages'
import { CreateCurrencyRoute, CurrenciesRoute, EditCurrencyRoute } from './currency/pages'
import { NftsRoute } from './nfts/pages'
import { TracksForRadioRoute } from './tracks/pages'
import { TracksForStyngsRoute } from './tracksForStyngs/pages'
import { ReportsRoute } from './reporting'
import { ActivityLogRoute } from './activityLog'

const DefaultRoute: Route = {
  path: BASE_PATH,
  component: () => <Redirect to={LoginRoute.path} />,
}

const NotFoundRoute: Route = {
  path: '*',
  name: 'Not Found',
  component: () => <div>not found</div>,
}

const AppRoute: Route = {
  path: '/',
  name: 'MainLayout',
  component: MainLayout,
  childRoutes: [
    MainRoute,
    PlaylistsRoute,
    PlaylistsCreateRoute,
    PlaylistEditRoute,
    StyngsRoute,
    CreateRFStyngRoute,
    EditRFStyngRoute,
    PlatformsRoute,
    PlatformCreateRoute,
    PlatformEditRoute,
    CompaniesRoute,
    CompaniesCreateRoute,
    CompaniesEditRoute,
    StynglistsRoute,
    CreateStynglistRoute,
    EditStynglistRoute,
    SoundboardRoute,
    CreateSoundboardRoute,
    EditSoundboardRoute,
    AppEventsRoute,
    CreateAppEventRoute,
    EditAppEventRoute,
    UsersRoute,
    UserCreateRoute,
    UserEditRoute,
    AppsRoute,
    AppInfoRoute,
    AppGeneralFormNewRoute,
    AppGeneralFormEditRoute,
    CurrenciesRoute,
    CreateCurrencyRoute,
    EditCurrencyRoute,
    TracksForRadioRoute,
    TracksForStyngsRoute,
    NftsRoute,
    ReportsRoute,
    ActivityLogRoute,
  ],
}

export const routes = [LoginRoute, AppRoute, DefaultRoute, NotFoundRoute]

const Root = () => {
  return <Router component={App} routeConfig={routes} baseUrlPath={BASE_PATH} />
}

export default Root
