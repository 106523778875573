import { observer } from 'mobx-react'
import { thread } from 'lib/fn/thread'

import TracksStore from '../TracksStore'
import Tracks from '../Tracks'

const TracksForRadioPage = () => {
  const tracksStore = new TracksStore()

  return <Tracks tracksStore={tracksStore} />
}

export default thread(TracksForRadioPage, [observer])
