/* eslint-disable jsx-control-statements/jsx-for-require-each */
import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { Duration } from 'luxon'

import Pagination from '../ui/Pagination/Pagination'
import SearchBar from '../ui/SearchBar/SearchBar'
import { Track } from '../common/api/track/track'
import Text from '../locale/strings'
import LoadingRemote from '../common/LoadingRemote'
import TracksForStyngsStore, { useLsrTracksPageQuery } from './TracksForStyngsStore'
import TrackFilter from './TrackFilter/TrackFilter'
import Table from '../ui/Table/Table'
import useStores from '../common/hook/useStore'
import StyngModal from '../styngs/StyngModal/StyngModal'

import styles from './tracksForStyngs.module.scss'

interface TracksProps {
  tracksStore: TracksForStyngsStore
}

const TracksForStyngs = ({ tracksStore }: TracksProps) => {
  const [filter, setFilter] = useState<string>('')
  // Add modal / Edit modal
  const [showModal, setShowModal] = useState<boolean>(false)
  const { data, status } = useLsrTracksPageQuery(tracksStore)

  const tracks = data?.tracks ?? []

  const numberOfPages = data?.numberOfPages ?? 10

  const { editStyngStore } = useStores()

  const handleChangePage = (value: number) => {
    tracksStore.changePage(value)
  }

  const handleSearch = (value: string) => {
    tracksStore.search(value)
  }

  // Table data
  const columns = [
    'Cover',
    'MediaNet ID',
    'ISRC',
    'Song name',
    'Explicit',
    'Available countries',
    'Label',
    'Label owner',
    'Artist',
    'Album',
    'Genres',
    'Duration',
    'Actions',
  ]

  const dataTable = tracks.map((track: Track) => [
    track.imageUrl,
    track.trackId,
    track.isrc,
    track.title,
    track.parentalAdvisory ? 'Yes' : 'No',
    track.availableCountries.length === 0 ? '/' : track.availableCountries.map((e) => `${e} `),
    track.label,
    track.labelOwner,
    track.artist,
    track.albumName,
    track.genre,
    Duration.fromISO(track.duration).toFormat('mm:ss'),
  ])

  const handleEditClick = (id: string) => {
    const track = tracks.filter((item) => item.trackId === id)

    editStyngStore.setTrack(track[0])
    setShowModal(true)
  }

  const handleCloseModal = () => {
    editStyngStore.setTrack(null)
    setShowModal(false)
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <SearchBar
          hideResults
          label={Text.page.tracks.searchPlaceholder}
          filter={filter}
          onChangeFilter={setFilter}
          onSearch={handleSearch}
        />
      </div>
      <TrackFilter tracksStore={tracksStore} />
      <LoadingRemote status={status}>
        {tracks.length > 0 ? (
          <Table createStyng hideEdit hideDelete columns={columns} data={dataTable!} onEditClick={handleEditClick} />
        ) : (
          <p>There are no results that match your search</p>
        )}
      </LoadingRemote>
      <If condition={tracks.length > 0}>
        <div className={styles.paginationContainer}>
          <div className={styles.pagination}>
            <Pagination page={tracksStore.page} count={numberOfPages} onChange={handleChangePage} />
          </div>
        </div>
      </If>
      {showModal && (
        <StyngModal editStyngStore={editStyngStore} styngId={''} open={showModal} handleClose={handleCloseModal} />
      )}
    </div>
  )
}

export default observer(TracksForStyngs)
