import { observer } from 'mobx-react'
import { thread } from 'lib/fn/thread'

import LoadingRemote from '../../common/LoadingRemote'
import { useAppEventsQuery } from '../../appEvent/hooks'
import AppEvents from '../../appEvent/AppEvents/AppEvents'

const AppEventsPage = () => {
  const { data, status } = useAppEventsQuery()
  const events = data?.events ?? []

  return (
    <LoadingRemote status={status}>
      <AppEvents events={events} />
    </LoadingRemote>
  )
}

export default thread(AppEventsPage, [observer])
