import React, { useState, useEffect } from 'react'
import * as R from 'ramda'

import MuiChip from '@mui/material/Chip'
import InfoIcon from '@mui/icons-material/Info'
import Tooltip from '@mui/material/Tooltip'

import Text from '../../locale/strings'

import { usePredefinedClassIfFound } from '../../utils/classNamesHelpers'
import styles from './tags.module.scss'

interface TagsProps {
  label?: string
  handleTagsUpdate?: (tagsArray: any[]) => void
  tagInputType?: string
  maxInputLength?: number
  wrapperClassNames?: string
  titleTooltip?: string[]
}

const Tags = ({
  label,
  handleTagsUpdate,
  tagInputType,
  maxInputLength,
  wrapperClassNames,
  titleTooltip,
}: TagsProps) => {
  const [tags, setTags] = useState<Array<any>>([])
  const [tagInputValue, setTagInputValue] = useState<any>('')
  const [error, setError] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState<string>('')

  const renderTooltipText = () => {
    if (!titleTooltip) {
      return
    }

    const listItems = titleTooltip.map((textLine, index) => <div key={index}>{textLine}</div>)

    return <React.Fragment>{listItems}</React.Fragment>
  }

  const handleDelete = (tagName: string | number) => {
    setTags((tags) => tags.filter((hashtag) => hashtag !== tagName))
  }

  const handleInputTagChange = (value: string | number) => {
    setTagInputValue(value)
  }

  const handleValidation = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (maxInputLength == null) {
      return
    }

    if (event.key !== 'Enter' && event.key !== 'Backspace' && tagInputValue.length >= maxInputLength) {
      event.preventDefault()
      setError(true)
      setErrorMsg(`Input can take maximum ${maxInputLength} characters`)
    } else {
      setError(false)
      setErrorMsg('')
    }
  }

  const handleAddTag = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const newTag = tagInputValue.trim()

    if (typeof tagInputValue === undefined) {
      return
    }

    if (event.key === 'Enter') {
      if (!tags.includes(newTag) && newTag !== '') {
        setTags([...tags, newTag])
        setTagInputValue('')
      }
    } else if (event.key === 'Backspace') {
      if (tagInputValue === '') {
        setTags(tags.slice(0, -1))
      }
    }
  }

  const handlePasteValue = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault()

    const arrayOfInsertedValues = event.clipboardData
      .getData('text')
      .split(',')
      .map((item) => {
        return item.trim()
      })

    const arrayOfInsertedValuesWithoutDuplicates = arrayOfInsertedValues.filter(
      (item, itemPosition, arrayOfInsertedValuesSelf) => {
        return arrayOfInsertedValuesSelf.indexOf(item) === itemPosition
      },
    )

    const validatedValues = arrayOfInsertedValuesWithoutDuplicates.filter((value) => {
      const onlyContainsNumbers = (numberValidationValue: string) => /^\d+$/.test(numberValidationValue)
      const onlyNumbers = tagInputType === 'number'
      const maxLengthIsSet = typeof maxInputLength === 'number'

      if (tags.includes(value) || value === '') {
        return false
      } else if (onlyNumbers && !onlyContainsNumbers(value.trim())) {
        return false
      } else if (maxLengthIsSet && !(value.length <= maxInputLength)) {
        return false
      }

      return true
    })

    setTags([...tags, ...validatedValues])
  }

  const handleBlur = () => {
    setTagInputValue('')
  }

  useEffect(() => {
    handleTagsUpdate && handleTagsUpdate(tags)
  }, [tags])

  return (
    <div
      className={`${styles.tagsContainer} ${usePredefinedClassIfFound(wrapperClassNames, styles)} ${
        error ? styles.errorMsg : ''
      }`}
    >
      <If condition={!R.isNil(label)}>
        <div className={styles.titleWrapper}>
          <h5 className={styles.label}>{label}</h5>
          {titleTooltip && (
            <Tooltip className={styles.titleTooltip} title={<React.Fragment>{renderTooltipText()}</React.Fragment>}>
              <InfoIcon color="action" sx={{ marginLeft: 1, cursor: 'pointer' }} />
            </Tooltip>
          )}
        </div>
      </If>
      <div className={styles.innerContainer}>
        <input
          type={tagInputType ?? 'text'}
          value={tagInputValue}
          className={styles.tagsInput}
          placeholder={Text.fields.inputPlaceholder}
          min="0"
          onChange={(event) => handleInputTagChange(event.target.value)}
          onKeyDown={handleValidation}
          onKeyUp={handleAddTag}
          onPaste={handlePasteValue}
          onBlur={handleBlur}
        />
      </div>

      {tags.map((element, index) => {
        return <MuiChip key={index} label={element} className={styles.chip} onDelete={() => handleDelete(element)} />
      })}
      {error && <div className={styles.errorContainer}>{errorMsg}</div>}
    </div>
  )
}

export default Tags
