import useStores from '../../common/hook/useStore'

import InfoContainer from '../../ui/InfoContainer/InfoContainer'
import Text from '../../locale/strings'
import Button from '../../ui/Button/Button'

import styles from './additionalTableAppInfo.module.scss'

const AdditionTableAppInfo = (props: any) => {
  const { notificationsStore } = useStores()
  const data = props[0]

  const handleCopyPublickKey = (value: string) => {
    navigator.clipboard.writeText(value)
    notificationsStore.successNotification('Public key is copied')
  }

  const handleCopyPrivateKey = (value: string) => {
    navigator.clipboard.writeText(value)
    notificationsStore.successNotification('Private key is copied')
  }

  const handleApiKey = (value: string) => {
    navigator.clipboard.writeText(value)
    notificationsStore.successNotification('Api key is copied')
  }

  return (
    <div className={styles.container}>
      <div className={styles.infoContainer}>
        <h3 className={styles.title}>{data.name}</h3>
        <InfoContainer label={Text.fields.linkPayout} value={data.paymentSystemUrl ?? '/'} />
        <div className={styles.btnContainer}>
          <Button className={styles.buttons} onClick={() => handleCopyPublickKey(data.publicKey)}>
            {'Copy ' + Text.fields.publickKey}
          </Button>
          <Button className={styles.buttons} onClick={() => handleCopyPrivateKey(data.secretKey)}>
            {'Copy ' + Text.fields.privateKey}
          </Button>
          <Button className={styles.buttons} onClick={() => handleApiKey(data.apiKey)}>
            {'Copy ' + Text.fields.apiKey}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default AdditionTableAppInfo
