/* eslint-disable @typescript-eslint/indent */
/* eslint-disable jsx-control-statements/jsx-use-if-tag */
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import * as R from 'ramda'
import * as yup from 'yup'
import { observer } from 'mobx-react'
import { useFormik } from 'formik'
import { useMutation, useQueryClient } from 'react-query'

import useStores from 'common/hook/useStore'

import { Toolbar, Typography } from '@mui/material'

import Text from 'locale/strings'
import Button, { ButtonColor, ButtonVariant } from 'ui/Button/Button'
import { EditUserRequest, EditUserResponse } from 'common/api/user/user'
import API from 'common/api/index'
import TextField from 'ui/TextField/TextField'
import PasswordEye from 'ui/PasswordEye/PasswordEye'

import { UsersRoute } from '../..'
import { useUserQuery } from '../../UsersStore'
import Spinner from 'ui/Spinner/Spinner'

import styles from './User.module.scss'

const PASSWORD_REG_EX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])(?=.{8,})/

interface UserFormValues {
  firstName: string
  lastName: string
  middleName: string
  email: string
  oldPassword: string | null
  newPassword: string | null
  password: string
  companies: string
}

interface ParamProps {
  id?: string
}

const User = () => {
  const { id = '' } = useParams<ParamProps>()
  const { notificationsStore, navigationStore } = useStores()
  const [visiblePass, setVisiblePass] = useState(false)
  const queryClient = useQueryClient()
  const { data: user } = useUserQuery(id)
  const initialUser = id !== '' ? user : null

  const initialValues: UserFormValues = {
    firstName: '',
    lastName: '',
    middleName: '',
    email: '',
    oldPassword: '',
    newPassword: '',
    password: '',
    companies: '',
  }

  const schema = yup.object().shape(
    {
      firstName: yup.string().required('First name is required'),
      lastName: yup.string().required('Last name is required'),
      email: yup.string().email('Enter a valid email').required('Email is required'),
      oldPassword: yup
        .string()
        .ensure()
        .when('newPassword', {
          is: (newPassword: string) => newPassword?.length > 0,
          then: yup
            .string()
            .required('Old password is required')
            .matches(PASSWORD_REG_EX, Text.errors.passwordConstraintsNotMet),
        }),
      newPassword: yup
        .string()
        .ensure()
        .when('oldPassword', {
          is: (oldPassword: string) => oldPassword?.length > 0,
          then: yup
            .string()
            .required('New password is required')
            .matches(PASSWORD_REG_EX, Text.errors.passwordConstraintsNotMet),
        }),
      password: R.isNil(initialUser)
        ? yup.string().required('Password is required').matches(PASSWORD_REG_EX, Text.errors.passwordConstraintsNotMet)
        : yup.string().matches(PASSWORD_REG_EX, Text.errors.passwordConstraintsNotMet),
    },
    [['oldPassword', 'newPassword']],
  )

  const mutationUser = useMutation<EditUserResponse, Error, EditUserRequest>(
    (body: EditUserRequest) => {
      if (!R.isNil(initialUser)) {
        return API.user.update({ ...body, userId: initialUser.userId })
      }

      return API.user.create(body)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('users')
        queryClient.invalidateQueries('user')

        navigationStore.goToPage(UsersRoute.path)

        !R.isNil(initialUser)
          ? notificationsStore.successNotification('User successfully edited')
          : notificationsStore.successNotification('User successfully added')
      },
      onError: (error: any) => {
        notificationsStore.errorNotification(error)
      },
    },
  )

  const handleSubmit = async (values: UserFormValues) => {
    if (R.isNil(initialUser)) {
      const newBody = {
        firstName: values.firstName.trim(),
        lastName: values.lastName.trim(),
        middleName: values.middleName.trim(),
        email: values.email,
        password: values.password.trim(),
        companyId: values.companies,
      }

      mutationUser.mutate(newBody)
    } else {
      const editBodyPass = {
        firstName: values.firstName.trim(),
        lastName: values.lastName.trim(),
        middleName: values.middleName.trim(),
        email: values.email,
        oldPassword: values.oldPassword !== null ? values.oldPassword.trim() : null,
        newPassword: values.newPassword !== null ? values.newPassword.trim() : null,
      }

      const editBody = {
        firstName: values.firstName.trim(),
        lastName: values.lastName.trim(),
        middleName: values.middleName.trim(),
        email: values.email,
        password: values.password.trim(),
      }

      mutationUser.mutate(values.oldPassword === '' ? editBody : editBodyPass)
    }
  }

  const formik = useFormik<UserFormValues>({
    initialValues: initialValues,
    validationSchema: schema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: handleSubmit,
  })

  const { values, resetForm } = formik

  const handleClickVisible = () => {
    setVisiblePass(R.not)
  }

  useEffect(() => {
    if (!R.isNil(initialUser)) {
      resetForm({
        values: {
          firstName: initialUser.firstName,
          lastName: initialUser.lastName,
          middleName: initialUser?.middleName ?? '',
          email: initialUser.email,
          oldPassword: '',
          newPassword: '',
          password: '',
          companies: '',
        },
      })
    }
  }, [initialUser, resetForm])

  return (
    <React.Fragment>
      {id !== '' && R.isNil(initialUser) ? (
        <Spinner />
      ) : (
        <React.Fragment>
          <Toolbar className={styles.toolbar}>
            <Typography sx={{ ml: 2, flex: 1, color: '#fff' }} variant="h6" component="div">
              {!R.isNil(initialUser) ? Text.navigation.editUser : Text.navigation.createUser}
            </Typography>
          </Toolbar>
          <div className={styles.container}>
            <div className={styles.form}>
              <TextField
                required
                fullWidth
                data-test="first-name-field"
                autoComplete="off"
                label={Text.fields.firstName}
                name="firstName"
                value={values.firstName}
                error={formik.errors.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <TextField
                required
                fullWidth
                data-test="last-name-field"
                autoComplete="off"
                label={Text.fields.lastName}
                name="lastName"
                value={values.lastName}
                error={formik.errors.lastName}
                onChange={formik.handleChange}
              />
              <TextField
                fullWidth
                data-test="middle-name-field"
                autoComplete="off"
                label={Text.fields.middleName}
                name="middleName"
                value={values.middleName}
                onChange={formik.handleChange}
              />
              <TextField
                fullWidth
                required
                data-test="new-email-field"
                disabled={!R.isNil(initialUser)}
                autoComplete="new-email"
                label={Text.fields.email}
                name="email"
                value={values.email}
                error={formik.errors.email}
                onChange={formik.handleChange}
              />
              {!R.isNil(initialUser) && (
                <React.Fragment>
                  <TextField
                    fullWidth
                    required
                    data-test="old-password-field"
                    label={Text.fields.oldPassword}
                    name="oldPassword"
                    autoComplete="old-password"
                    type={visiblePass ? 'text' : 'password'}
                    value={values.oldPassword}
                    error={formik.errors.oldPassword}
                    adornment={<PasswordEye visible={visiblePass} onClick={handleClickVisible} />}
                    onChange={formik.handleChange}
                  />
                  <TextField
                    fullWidth
                    required
                    data-test="new-password-field"
                    label={Text.fields.newPassword}
                    name="newPassword"
                    autoComplete="new-password"
                    type={visiblePass ? 'text' : 'password'}
                    value={values.newPassword}
                    error={formik.errors.newPassword}
                    adornment={<PasswordEye visible={visiblePass} onClick={handleClickVisible} />}
                    onChange={formik.handleChange}
                  />
                </React.Fragment>
              )}
              {R.isNil(initialUser) && (
                <TextField
                  fullWidth
                  required
                  data-test="new-password-field"
                  autoComplete="new-password"
                  label={Text.fields.password}
                  name="password"
                  type={visiblePass ? 'text' : 'password'}
                  value={values.password}
                  error={formik.errors.password}
                  adornment={<PasswordEye visible={visiblePass} onClick={handleClickVisible} />}
                  onChange={formik.handleChange}
                />
              )}
              <div className={styles.submitContainer}>
                <Button
                  data-test="cancel-button"
                  variant={ButtonVariant.OUTLINED}
                  onClick={() => navigationStore.goToPage(UsersRoute.path)}
                >
                  {Text.common.cancel}
                </Button>
                <Button
                  data-test="submit-button"
                  disabled={!R.isNil(initialUser) && !(formik.isValid && formik.dirty)}
                  loading={mutationUser.isLoading}
                  color={
                    !R.isNil(initialUser) && !(formik.isValid && formik.dirty) ? ButtonColor.INFO : ButtonColor.PRIMARY
                  }
                  onClick={formik.handleSubmit}
                >
                  {Text.common.save}
                </Button>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default observer(User)
