import useStores from '../../../common/hook/useStore'
import { useQuery } from 'react-query'
import API from '../../../common/api'
import { TracksFromPlaylistResponse, TracksFromRoyaltyFreePlaylistResponse } from '../../../common/api/track/track'
import PaginationStore from '../../../common/PaginationStore'

class TracksInPlaylistStore extends PaginationStore {}

export const useTracksInPlaylistQuery = (playlistId: string) => {
  const { tracksInPlaylistStore } = useStores()

  const { tracksInPlaylistPageSize, page } = tracksInPlaylistStore

  return useQuery<TracksFromPlaylistResponse>(
    [`playlist-${playlistId}-tracks`, tracksInPlaylistPageSize, page, playlistId],
    () => {
      return API.playlist.getTracksFromPlaylist({
        page,
        size: tracksInPlaylistPageSize,
        format: 'AAC',
        bitrate: 'KBPS64',
        playlistId: playlistId,
      })
    },
    {
      retry: 1,
      keepPreviousData: true,
    },
  )
}

export const useRoyaltyFreeTracksInPlaylistQuery = (playlistId: string) => {
  const { tracksInPlaylistStore } = useStores()

  const { tracksInPlaylistPageSize, page } = tracksInPlaylistStore

  return useQuery<TracksFromRoyaltyFreePlaylistResponse>(
    [`royalty-free-playlist-${playlistId}-tracks`, tracksInPlaylistPageSize, page, playlistId],
    () => {
      return API.playlist.getTracksFromRoyaltyFreePlaylist({
        page,
        size: tracksInPlaylistPageSize,
        playlistId: playlistId,
      })
    },
    {
      retry: 1,
      keepPreviousData: true,
    },
  )
}

export default TracksInPlaylistStore
