import { useQuery } from 'react-query'

import PaginationStore from 'common/PaginationStore'
import API from 'common/api'
import useStores from 'common/hook/useStore'
import { TerritoriesResponse } from 'common/api/territory/territory'

class TerritoriesStore extends PaginationStore {}

export const useTerritoriesQuery = () => {
  const { territoriesStore } = useStores()

  const { pageSize, page } = territoriesStore

  return useQuery<TerritoriesResponse>(
    ['territories', pageSize, page],
    () => {
      return API.territories.list({
        size: pageSize,
        page: page,
      })
    },
    {
      retry: 1,
      keepPreviousData: true,
    },
  )
}

export default TerritoriesStore
