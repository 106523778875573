import React from 'react'
import { observer } from 'mobx-react'
import { Redirect } from 'react-router'

import { BASE_PATH, Permission } from '../common/constants'
import useStores from '../common/hook/useStore'
import logger from 'lib/logger'

export const PermissionValidationType = {
  HAS_ANY: 'HAS_ANY',
  HAS_ALL: 'HAS_ALL',
}

const PermissionValidationHOC = (permission: Permission) => (Component: React.FC) => {
  const PermissionValidation: React.FC = ({ children }) => {
    const { userStore } = useStores()

    if (userStore.hasPermission(permission)) {
      return <Component>{children}</Component>
    }

    logger.debug('Redirect')

    return <Redirect to={BASE_PATH} />
  }

  return observer(PermissionValidation)
}

export default PermissionValidationHOC
