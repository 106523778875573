/* eslint-disable max-len */

type JsonRuleColor = {
  rule: boolean
}

export const JsonValidationIcon = (rule: JsonRuleColor) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      version="1"
      viewBox="0 0 512 512"
      fill={rule.rule ? '#009900' : '#000'}
    >
      <path
        d="M2195 4679c-147-12-321-37-483-70-484-100-848-271-1069-503-99-104-142-170-181-278l-27-73V1365l27-73c41-114 84-178 193-288 107-108 196-173 346-253 363-191 905-312 1415-315l220-1 40 27c59 41 87 90 92 158 2 32-1 74-7 93-16 48-84 115-130 127-20 5-133 14-251 20-474 23-869 112-1171 266-167 85-279 179-331 276-22 41-23 56-26 271-2 125 0 227 3 227 4 0 19-9 34-19 43-31 237-125 341-166 332-130 797-212 1230-218 149-2 157-1 200 23 67 38 103 96 108 174 5 74-16 125-72 173-49 44-80 51-276 57-660 22-1231 199-1474 456-90 96-96 118-96 382v209l43-26c832-511 2502-511 3334 0l42 26 3-244 3-244 27-39c15-21 44-50 65-64 31-21 48-25 113-25 67 0 81 3 116 27 21 15 50 44 64 65l25 37v1271l-27 73c-39 108-82 174-183 280-292 307-864 516-1569 572-147 11-563 11-711-1zm783-435c536-55 1013-230 1204-442 116-129 110-244-22-373-222-217-703-380-1265-428-153-14-515-14-665-1-568 49-1057 215-1275 434-136 136-132 264 15 400 258 238 767 392 1425 430 109 6 438-5 583-20z"
        transform="matrix(.1 0 0 -.1 0 512)"
      />
      <path
        d="M3755 2116c-36-16-75-52-101-91-17-26-20-56-24-280l-5-250-251-5-251-5-39-27c-21-15-50-44-64-65-21-31-25-48-25-113s4-82 25-113c14-21 43-50 64-65l39-27 251-5 251-5 5-251c5-247 5-251 30-287 14-21 43-50 64-65 35-24 49-27 116-27s81 3 116 27c21 15 50 44 64 65 25 36 25 40 30 287l5 251 251 5 251 5 39 27c21 15 50 44 64 65 21 31 25 48 25 113 0 67-3 81-27 116-15 21-44 50-65 64-36 25-40 25-287 30l-251 5-5 251-5 251-27 39c-44 63-90 88-167 91-41 2-77-2-96-11z"
        transform="matrix(.1 0 0 -.1 0 512)"
      />
    </svg>
  )
}
