import { action, computed, observable } from 'mobx'

import * as R from 'ramda'

import { StoreState } from '../common/constants'

const defaultRequest = () => {
  throw new Error('Error')
}

interface IFetchStore {
  request: any
}

export type RequestType = (data: any) => Promise<any>

export default class AbstractFetchStore<T> implements IFetchStore {
  public request: RequestType

  constructor(request: RequestType = defaultRequest) {
    this.request = request
  }

  @observable data: Nullable<T> = null
  @observable requestData = null
  @observable defaultRequest: object = {}
  @observable state: Nullable<StoreState> = null
  @observable errorMessage: Nullable<string> = null
  @observable errorCode: Nullable<number> = null

  @computed
  get isPending() {
    return R.or(R.isNil(this.state), R.equals(this.state, StoreState.PENDING))
  }

  @computed
  get isError() {
    return R.equals(this.state, StoreState.ERROR)
  }

  @computed
  get isDone() {
    return R.equals(this.state, StoreState.DONE)
  }

  @action
  setDefaultRequest = (defaultReq: any) => {
    this.defaultRequest = defaultReq
  }

  @action
  setData = (data: Nullable<T>) => {
    this.data = data
  }

  @action
  setRequestData = (requestData: any) => {
    this.requestData = requestData
  }

  @action
  setState = (state: Nullable<StoreState>) => {
    this.state = state
  }

  @action
  setErrorCode = (errorCode: Nullable<number>) => {
    this.errorCode = errorCode
  }

  @action
  setErrorMessage = (errorMessage: Nullable<string>) => {
    this.errorMessage = errorMessage
  }

  @action
  resetErrors = () => {
    this.setErrorCode(null)
    this.setErrorMessage(null)
    this.setState(null)
  }

  async loadData(params: any) {
    try {
      this.setState(StoreState.PENDING)

      const request = R.mergeDeepRight(params, this.defaultRequest)

      const data = await this.request(params)

      this.setRequestData(request)
      this.setData(data)
      this.setState(StoreState.DONE)

      return data
    } catch (error: any) {
      this.setState(StoreState.ERROR)

      if (error.errorCode) {
        this.setErrorCode(error.errorCode)
      }

      this.setErrorMessage(error?.errorMessage ?? error)
    }
  }
}
