import * as R from 'ramda'
import { History } from 'history'
// import { action, observable } from 'mobx'

import { routes } from '../Root'
import Route from 'lib/routing/Route'

export default class NavigationStore {
  private routes
  constructor(private history: History) {
    this.routes = routes
  }

  get currentRoute(): Nullable<Route> {
    const path = this.history.location.pathname

    return this.routes.find(R.propEq('path', path))
  }

  get currentPath(): string {
    return this.history.location.pathname
  }

  goToPage = (path: string) => {
    this.history.push(path)
  }

  // @observable
  // activePath: string = '/users'
  // @action
  // setPath = (path: string) => {
  //   this.activePath = path
  // }
}
