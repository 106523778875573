/* eslint-disable @typescript-eslint/indent */
/* eslint-disable react/jsx-sort-props */
/* eslint-disable jsx-control-statements/jsx-for-require-each */
import * as React from 'react'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import Tooltip from '@mui/material/Tooltip'
import Switch from '@mui/material/Switch'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import HistoryIcon from '@mui/icons-material/History'
import ReplayIcon from '@mui/icons-material/Replay'
import { defaultImg, PlaylistTypes } from '../../../common/constants'
import Text from '../../../locale/strings'
import mint from './mint.svg'
import addTrackIcon from './addIcon.svg'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import { JsonValidationIcon } from './images'

import styles from './row.module.scss'

interface RowProps {
  row: string[]
  columns: string[]
  createStyng?: boolean
  plusIcon?: boolean
  jsonData?: boolean
  hideActionColumn?: boolean
  disabledEdit?: boolean
  disabledPlus?: boolean
  disabledDelete?: boolean
  disabledToggle?: boolean
  disabledPlaylistToggle?: boolean
  hideDelete?: boolean
  hideEdit?: boolean
  hideColData?: boolean
  hiddenColumns?: string[]
  publishedToggle?: boolean
  mintButton?: boolean
  dmcaCheckButton?: boolean
  nftIcon?: boolean
  indexRow?: number
  dataAdditionalInfo?: any
  showDownloadTrack?: boolean
  additionalDataComponent?: (row: string[]) => React.ReactElement
  onEditClick?: (id: string) => void
  onCreateNftClick?: (id: string) => void
  onDmcaCheckClick?: (id: string, name?: string, numOfTracks?: string) => void
  onDeleteClick?: (id: string, name: string) => void
  handleActivate?: (id: string, active: string) => any
  prepareForMintNft?: (id: string) => void
  onJsonDataClick?: (id: string) => void
  onDownloadFile?: (id: string) => void
  onResubmitClick?: (id: string) => void
}

const Row = ({
  row,
  indexRow,
  columns,
  createStyng,
  plusIcon,
  jsonData,
  hideActionColumn,
  nftIcon,
  mintButton,
  dmcaCheckButton,
  publishedToggle,
  dataAdditionalInfo,
  disabledEdit,
  disabledPlus,
  disabledToggle,
  disabledPlaylistToggle,
  disabledDelete,
  hideDelete,
  hideEdit,
  hideColData,
  hiddenColumns,
  showDownloadTrack,
  handleActivate,
  onEditClick,
  onCreateNftClick,
  onDmcaCheckClick,
  onDeleteClick,
  additionalDataComponent,
  prepareForMintNft,
  onJsonDataClick,
  onDownloadFile,
  onResubmitClick,
}: RowProps) => {
  const [open, setOpen] = React.useState<boolean>(false)
  let dataAdditionalInfoForCollapse: string[] = []

  if (indexRow !== undefined) {
    dataAdditionalInfoForCollapse = dataAdditionalInfo?.[indexRow] ? dataAdditionalInfo?.[indexRow] : []
  }

  const idColIndex = dataAdditionalInfo ? columns.indexOf('Id') - 1 : columns.indexOf('Id')
  const mediaNetIdColIndex = dataAdditionalInfo ? columns.indexOf('MediaNet ID') - 1 : columns.indexOf('MediaNet ID')
  const nameColIndex = dataAdditionalInfo ? columns.indexOf('Name') - 1 : columns.indexOf('Name')
  const toggleColIndex = dataAdditionalInfo ? columns.indexOf('Published') - 1 : columns.indexOf('Published')
  const mintStatus = dataAdditionalInfo ? columns.indexOf('Status') - 1 : columns.indexOf('Status')
  const numOfTracks = dataAdditionalInfo ? columns.indexOf('Num of tracks') - 1 : columns.indexOf('Num of tracks')
  const typeColIndex = dataAdditionalInfo ? columns.indexOf('Type') - 1 : columns.indexOf('Type')
  const isRetryableColIndex = dataAdditionalInfo ? columns.indexOf('Is Retryable') - 1 : columns.indexOf('Is Retryable')

  const externalMetadataColIndex = dataAdditionalInfo
    ? columns.indexOf('External Metadata') - 1
    : columns.indexOf('External Metadata')

  const dmcaCheck = dataAdditionalInfo ? columns.indexOf('Dmca') - 1 : columns.indexOf('Dmca')
  const typeCheck = dataAdditionalInfo ? columns.indexOf('Type') - 1 : columns.indexOf('Type')
  const imageColIndex = dataAdditionalInfo ? columns.indexOf('Image') - 1 : columns.indexOf('Image')
  const imageSrc = row[imageColIndex] ?? defaultImg
  const coverColIndex = dataAdditionalInfo ? columns.indexOf('Cover') - 1 : columns.indexOf('Cover')
  const coverSrc = row[coverColIndex] ?? defaultImg

  return (
    <React.Fragment>
      <TableRow data-test="row" key={`row-${indexRow}-${row[idColIndex]}`} id={row[idColIndex]}>
        {dataAdditionalInfo && (
          <TableCell className={styles.collapseButton}>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        )}
        {/* render image first */}
        {row.map((item, index: number) => {
          if (columns[index] === 'Image') {
            return (
              <TableCell key={index}>
                <img
                  role="button"
                  onError={({ currentTarget }) => {
                    currentTarget.src = './default-image.png'
                  }}
                  className={styles.image}
                  src={imageSrc}
                  alt={imageSrc}
                  loading="lazy"
                />
              </TableCell>
            )
          } else if (columns[index] === 'Cover') {
            return (
              <TableCell key={index}>
                <img
                  role="button"
                  onError={({ currentTarget }) => {
                    currentTarget.src = './default-image.png'
                  }}
                  className={styles.image}
                  src={coverSrc}
                  alt={coverSrc}
                  loading="lazy"
                />
              </TableCell>
            )
          } else if (hideColData && (index === idColIndex || index === dmcaCheck || index === typeCheck)) {
            return (
              <TableCell className={styles.hidden} key={index}>
                {item}
              </TableCell>
            )
          } else if (columns[index] === 'Name' && hideColData && dataAdditionalInfo) {
            return <TableCell key={index}>{item}</TableCell>
          } else if (
            (index === toggleColIndex && hideColData && dataAdditionalInfo) ||
            (!dataAdditionalInfo && hiddenColumns?.includes(columns[index]))
          ) {
            return (
              <TableCell className={styles.hidden} key={index}>
                {item}
              </TableCell>
            )
          } else if (columns[index] === 'Published') {
            return false
          }

          return <TableCell key={index}>{item}</TableCell>
        })}
        {publishedToggle && (
          <TableCell>
            <Switch
              data-test="published-button"
              size="small"
              disabled={
                (disabledToggle && row[mintStatus] !== 'Minted') || (disabledPlaylistToggle && row[numOfTracks] === '0')
              }
              checked={row[toggleColIndex] === 'true'}
              onChange={() => handleActivate?.(row[idColIndex], row[toggleColIndex])}
            />
          </TableCell>
        )}
        {!hideActionColumn && (
          <TableCell>
            <div className={styles.actionColumn}>
              {jsonData && (
                <div
                  className={styles.svgIcon}
                  onClick={() => (onJsonDataClick ? onJsonDataClick(row[idColIndex]) : () => {})}
                >
                  <JsonValidationIcon rule={row[externalMetadataColIndex] !== 'NULL'} />
                </div>
              )}
              {!hideEdit &&
                (plusIcon ? (
                  <Tooltip placement="top" title="Add to playlist">
                    <img
                      role="button"
                      className={disabledPlus ? styles.disabledAddTrackIcon : styles.addTrackIcon}
                      src={addTrackIcon}
                      alt="plus-icon"
                      onClick={
                        disabledPlus
                          ? () => {}
                          : onEditClick
                          ? () => onEditClick(mediaNetIdColIndex ? row[mediaNetIdColIndex] : row[idColIndex])
                          : () => {}
                      }
                    />
                  </Tooltip>
                ) : (
                  <EditIcon
                    data-test="edit-button"
                    color={disabledEdit ? 'disabled' : 'action'}
                    className={disabledEdit ? styles.disabledEditIcon : styles.editIcon}
                    onClick={disabledEdit ? () => {} : () => (onEditClick ? onEditClick(row[idColIndex]) : () => {})}
                  />
                ))}
              {createStyng && (
                <div>
                  <Tooltip placement="top" title="Create a Styng">
                    <AddCircleIcon
                      role="button"
                      color="action"
                      className={styles.nftIcon}
                      onClick={onEditClick ? () => onEditClick(row[mediaNetIdColIndex]) : () => {}}
                    />
                  </Tooltip>
                </div>
              )}
              {!hideDelete && (
                <DeleteIcon
                  data-test="delete-button"
                  color={disabledDelete ? 'disabled' : 'action'}
                  className={disabledDelete ? styles.disabledDeleteIcon : styles.deleteIcon}
                  onClick={
                    disabledDelete
                      ? () => {}
                      : () => (onDeleteClick ? onDeleteClick(row[idColIndex], row[nameColIndex]) : () => {})
                  }
                />
              )}
              {showDownloadTrack && (
                <Tooltip placement="top" title="Download track">
                  <FileDownloadOutlinedIcon
                    className={styles.downloadTrackButton}
                    onClick={() =>
                      onDownloadFile
                        ? onDownloadFile(row[mediaNetIdColIndex] ? row[mediaNetIdColIndex] : row[idColIndex])
                        : () => {}
                    }
                  />
                </Tooltip>
              )}
              {nftIcon && process.env.REACT_APP_ENABLE_NFT && (
                <div>
                  <Tooltip placement="top" title={Text.common.createNft}>
                    <AddCircleIcon
                      color="action"
                      className={styles.nftIcon}
                      onClick={onCreateNftClick ? () => onCreateNftClick(row[idColIndex]) : () => {}}
                    />
                  </Tooltip>
                </div>
              )}
              {mintButton && (
                <img
                  role="button"
                  className={row[mintStatus] !== 'Draft' ? styles.disabledIcon : styles.mint}
                  src={mint}
                  alt="mint-nft"
                  onClick={
                    prepareForMintNft && row[mintStatus] === 'Draft'
                      ? () => prepareForMintNft(row[idColIndex])
                      : () => {}
                  }
                />
              )}
              {dmcaCheckButton && row[typeColIndex] === PlaylistTypes.LICENSED && (
                <Tooltip placement="top" title="Playlist duration">
                  <HistoryIcon
                    data-test="dmca-check-button"
                    color={row[dmcaCheck] === 'true' ? 'success' : 'error'}
                    className={styles.dmcaIcon}
                    onClick={
                      onDmcaCheckClick
                        ? () => onDmcaCheckClick(row[idColIndex], row[nameColIndex], row[numOfTracks])
                        : () => {}
                    }
                  />
                </Tooltip>
              )}
              {row[isRetryableColIndex] === 'true' && (
                <Tooltip placement="top" title="Retry">
                  <ReplayIcon
                    className={styles.dmcaIcon}
                    onClick={onResubmitClick ? () => onResubmitClick(row[idColIndex]) : () => {}}
                  />
                </Tooltip>
              )}
            </div>
          </TableCell>
        )}
      </TableRow>
      {dataAdditionalInfo && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={columns.length}>
            <Collapse unmountOnExit timeout="auto" in={open}>
              {additionalDataComponent?.(dataAdditionalInfoForCollapse)}
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  )
}

export default Row
