import { action, observable } from 'mobx'
import { useQuery } from 'react-query'
import * as R from 'ramda'

import { Track } from '../../common/api/track/track'
import { EditStyngEntity } from '../../common/api/styng/styng'
import API from '../../common/api'
import TracksStore from '../../tracks/TracksStore'

class EditStyngStore extends TracksStore {
  @observable
  currentTrack: Nullable<Track> = null
  @action
  setTrack = (track: Nullable<Track>) => {
    this.currentTrack = track
  }
}

export const useStyngQuery = (id: string) => {
  return useQuery<EditStyngEntity>(
    ['styng', id],
    () => {
      return (
        API.styngs.get({
          id,
        }) ?? []
      )
    },
    {
      refetchOnWindowFocus: false,
      enabled: !R.isEmpty(id),
      keepPreviousData: true,
      retry: 1,
    },
  )
}

export const useRoyaltyFreeStyngQuery = (id: string) => {
  return useQuery<any>(
    ['royalty-free-styng', id],
    () => {
      return (
        API.styngs.getRoyaltyFreeStyng({
          id,
        }) ?? []
      )
    },
    {
      refetchOnWindowFocus: false,
      enabled: !R.isEmpty(id),
      keepPreviousData: true,
      retry: 1,
    },
  )
}

export default EditStyngStore
