import React from 'react'
import MuiContainer from '@mui/material/Container'

interface IContainerProps {
  children: React.ReactElement[] | React.ReactElement
  className?: string
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl'
}

const Container: React.FC<IContainerProps> = ({ children, className, maxWidth, ...props }: IContainerProps) => {
  return (
    <MuiContainer {...props} maxWidth={maxWidth} className={className}>
      {children}
    </MuiContainer>
  )
}

export default Container
