import * as R from 'ramda'
import { action, observable } from 'mobx'
import { useQuery } from 'react-query'

import { SoundboardResponse } from '../common/api/soundboard/soundboard'
import PaginationStore from '../common/PaginationStore'
import API from '../common/api'

class SoundboardStore extends PaginationStore {
  @observable
  name: string = ''

  @observable
  id: string = ''

  @action
  handleNameSearch = (filter: string) => {
    this.name = filter?.trim()
    this.changePage()
  }

  @action
  handleIdSearch = (filter: string) => {
    this.id = filter?.trim()
    this.changePage()
  }
}

export const useSoundboardListQuery = (filterStore: SoundboardStore) => {
  const { pageSize, page, name, id } = filterStore

  return useQuery<SoundboardResponse>(
    ['soundboard-list', pageSize, page, name, id],
    () => {
      return API.soundboard.list({
        page: page,
        size: pageSize,
        name,
        id,
      })
    },
    {
      retry: 1,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    },
  )
}

export const useSoundboardQuery = (id: Nullable<string> = null) => {
  return useQuery<any>(
    ['soundboard'],
    () => {
      return API.soundboard.getSoundboard({
        soundboardId: id,
      })
    },
    {
      enabled: !R.isEmpty(id),
      keepPreviousData: true,
      retry: 1,
    },
  )
}

export default SoundboardStore
