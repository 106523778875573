import { useQuery } from 'react-query'
import API from '../common/api'
import * as R from 'ramda'
import { observable } from 'mobx'

import { Platform, PlatformResponse } from '../common/api/platforms/platforms'
import PaginationStore from '../common/PaginationStore'

class PlatformsStore extends PaginationStore {
  @observable
  platformId: string = ''
}

export const usePlatforsmListQuery = (filterStore: PlatformsStore) => {
  const { pageSize, page } = filterStore

  return useQuery<PlatformResponse>(
    ['platforms-list', pageSize, page],
    () => {
      return API.platforms.list({
        page: page,
        size: pageSize,
      })
    },
    {
      keepPreviousData: false,
      retry: 1,
      refetchOnWindowFocus: false,
    },
  )
}

export const usePlatforsmAppsListQuery = (platformId: string) => {
  return useQuery<any>(
    [`platform-apps-list-${platformId}`],
    () => {
      return API.platforms.getPlatformAppslist({
        platformId: platformId,
      })
    },
    {
      enabled: false,
      retry: 1,
    },
  )
}

export const usePlatformQuery = (id: string) => {
  return useQuery<Platform>(
    ['platform', id],
    () => {
      return API.platforms.getPlatform({
        platformId: id,
      })
    },
    {
      enabled: !R.isEmpty(id),
      keepPreviousData: true,
      retry: 1,
    },
  )
}

export default PlatformsStore
