/* eslint-disable jsx-control-statements/jsx-for-require-each */
import React from 'react'
import { observer } from 'mobx-react'
import { Duration } from 'luxon'

import useStores from 'common/hook/useStore'
import { useStynglistsQuery } from 'stynglists/StynglistsStore'

import { CreateStynglistRoute, EditStynglistRoute } from '../../index'

import { Stynglist, ActivateStynglist, DeactivateStynglist } from 'common/api/stynglist/stynglist'
import Spinner from 'ui/Spinner/Spinner'
import SectionFilters from 'ui/SectionFilters/SectionFilters'
import Table from 'ui/Table/Table'
import { useMutation } from 'react-query'
import { formatGenres } from 'common/hook/formatGenre'
import Button from 'ui/Button/Button'
import API from 'common/api'
import Text from 'locale/strings'

import { truncateText } from 'common/utils'
import { Permission, FilterFields, ApiStatuses } from 'common/constants'
import Pagination from 'ui/Pagination/Pagination'

import styles from './StynglistsTable.module.scss'

const StynglistsTable = () => {
  const { navigationStore, userStore, notificationsStore, stynglistsStore } = useStores()

  const { data, refetch, status } = useStynglistsQuery(stynglistsStore)

  const stynglists = data?.items ?? []
  const pageCount = data?.pagesCount ?? 1

  const columns = ['Image', 'Id', 'Name', 'App', 'Genres', 'Duration', 'Published', 'Actions']

  const dataTable = stynglists?.map((stynglists: Stynglist) => [
    stynglists?.imageUrl,
    stynglists?.id,
    stynglists?.name && truncateText(stynglists?.name),
    stynglists?.applicationName && truncateText(stynglists?.applicationName),
    formatGenres(stynglists?.genres),
    Duration.fromISO(stynglists?.duration).toFormat('mm:ss'),
    stynglists.isActive.toString(),
  ])

  const onActivateMutation = useMutation<null, Error, ActivateStynglist>(
    (id) => {
      return API.stynglist.activate(id)
    },
    {
      onSuccess: () => {
        refetch()
      },
      onError: (error: any) => {
        notificationsStore.errorNotification(error)
      },
    },
  )

  const onDeactivateMutation = useMutation<null, Error, DeactivateStynglist>(
    (id) => {
      return API.stynglist.deactivate(id)
    },
    {
      onSuccess: () => {
        refetch()
      },
      onError: (error: any) => {
        notificationsStore.errorNotification(error)
      },
    },
  )

  const handleActivate = (id: string, active: string) => {
    if (active === 'false') {
      onActivateMutation.mutate({ stynglistId: id })
    } else {
      onDeactivateMutation.mutate({ stynglistId: id })
    }
  }

  const handleCreateClick = () => {
    navigationStore.goToPage(CreateStynglistRoute.path)
  }

  const handleEditClick = (id: string) => {
    navigationStore.goToPage(EditStynglistRoute.getUrl({ id: id }))
  }

  const handleChangePage = (value: number) => {
    stynglistsStore.changePage(value)
  }

  return (
    <div className={styles.container}>
      <If condition={userStore.hasPermission(Permission.CREATE_STYNGLIST)}>
        <div className={styles.header}>
          <Button data-test="create-button" onClick={() => handleCreateClick()}>
            {Text.page.stynglists.newStynglist}
          </Button>
        </div>
      </If>
      <SectionFilters
        sectionStore={stynglistsStore}
        filters={[
          FilterFields.ID,
          FilterFields.NAME,
          FilterFields.APPLICATION,
          FilterFields.GENRE,
          FilterFields.PUBLISHED,
        ]}
      />
      {onActivateMutation.isLoading || onDeactivateMutation.isLoading || status === ApiStatuses.LOADING ? (
        <Spinner />
      ) : (
        <React.Fragment>
          <Table
            hideDelete
            columns={columns}
            data={dataTable}
            publishedToggle={true}
            handleActivate={handleActivate}
            onEditClick={handleEditClick}
          />
          <If condition={pageCount > 1}>
            <div className={styles.paginationContainer}>
              <div className={styles.pagination}>
                <Pagination page={stynglistsStore.page} count={pageCount} onChange={handleChangePage} />
              </div>
            </div>
          </If>
        </React.Fragment>
      )}
    </div>
  )
}

export default observer(StynglistsTable)
