/* eslint-disable jsx-quotes */
import { useState } from 'react'
import { observer } from 'mobx-react'
import API from 'common/api'
import { useMutation } from 'react-query'

import { UserCreateRoute, UserEditRoute } from '../..'
import UsersStore, { useUsersQuery } from '../../UsersStore'

import SectionFilters from 'ui/SectionFilters/SectionFilters'
import Pagination from 'ui/Pagination/Pagination'
import Button from 'ui/Button/Button'
import Table from 'ui/Table/Table'
import DeleteModal from 'ui/DeleteModal/DeleteModal'
import Spinner from 'ui/Spinner/Spinner'

import Text from 'locale/strings'
import { truncateText } from 'common/utils'
import { FilterFields } from 'common/constants'

import { User as UserType } from 'common/api/user/user'

import useStores from 'common/hook/useStore'

import styles from './UsersTable.module.scss'

interface UsersTableProps {
  usersStore: UsersStore
}

const UsersTable = ({ usersStore }: UsersTableProps) => {
  const { notificationsStore, navigationStore } = useStores()
  const { data, status, refetch } = useUsersQuery(usersStore)
  const users = data?.items ?? []
  const numberOfPages = data?.pageCount ?? 10
  const [selectedUserId, setSelectedUserId] = useState<string>('')
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [userName, setUserName] = useState<string>('')

  const handleEditClick = (id: string) => {
    navigationStore.goToPage(UserEditRoute.getUrl({ id: id }))
  }

  const handleCreateUser = () => {
    navigationStore.goToPage(UserCreateRoute.path)
  }

  const handleDeleteClick = (id: string, name: string) => {
    setSelectedUserId(id)
    setUserName(name)
    setShowDeleteModal(true)
  }

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false)
    setSelectedUserId('')
  }

  const onDeleteMutation = useMutation(
    () => {
      return API.user.delete({ id: selectedUserId })
    },
    {
      onSuccess: () => {
        refetch()
        setShowDeleteModal(false)
        notificationsStore.successNotification('User successfully deleted')
      },
      onError: (error: any) => {
        setShowDeleteModal(false)
        notificationsStore.errorNotification(error)
      },
    },
  )

  const handleDeleteUser = () => {
    onDeleteMutation.mutate()
  }

  const handleChangePage = (value: number) => {
    usersStore.changePage(value)
  }

  const columns = ['Id', 'Name', 'Actions']

  const dataTable = users.map((user: UserType) => [
    user.userId,
    truncateText(`${user.firstName} ${user.middleName ?? ''} ${user.lastName}`),
  ])

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Button data-test="create-button" onClick={handleCreateUser}>
          {Text.navigation.createUser}
        </Button>
      </div>

      <SectionFilters sectionStore={usersStore} filters={[FilterFields.ID, FilterFields.NAME]} />
      <div className={styles.tableWrapper}>
        {status === 'loading' ? (
          <Spinner />
        ) : (
          <Table columns={columns} data={dataTable} onEditClick={handleEditClick} onDeleteClick={handleDeleteClick} />
        )}

        {users.length > 0 && (
          <div>
            <div className={styles.paginationContainer}>
              <div className={styles.pagination}>
                <Pagination page={usersStore.page} count={numberOfPages} onChange={handleChangePage} />
              </div>
            </div>
          </div>
        )}
      </div>
      {showDeleteModal && (
        <DeleteModal
          open={showDeleteModal}
          headerContent={'Delete user'}
          handleClose={handleCloseDeleteModal}
          text={`Do you want to delete user ${truncateText(userName)}?`}
          handleSubmit={handleDeleteUser}
        />
      )}
    </div>
  )
}

export default observer(UsersTable)
