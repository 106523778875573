import { observer } from 'mobx-react'
import { thread } from 'lib/fn/thread'

import ApplicationStore from '../ApplicationStore'

import AppsTable from './AppsTable/AppsTable'

const AppsPage = () => {
  const applicationStore = new ApplicationStore()

  return <AppsTable applicationStore={applicationStore} />
}

export default thread(AppsPage, [observer])
