/* eslint-disable jsx-control-statements/jsx-for-require-each */
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useMutation } from 'react-query'
import { observer } from 'mobx-react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import * as R from 'ramda'

import useStores from '../../../common/hook/useStore'

import { useAdGroupQuery } from '../../AdGroupsStore'

import AdItemWithMoreInfo from '../../AdItemWithMoreInfo/AdItemWithMoreInfo'

import { Box } from '@mui/material'

import Spinner from '../../../ui/Spinner/Spinner'
import Text from '../../../locale/strings'
import TextField from '../../../ui/TextField/TextField'
import Button from '../../../ui/Button/Button'
import RadioAd from 'radioAds/pages/RadioAd/RadioAd'

import API from '../../../common/api'
import { Ad, AdBlockData, RadioAdGroupRequest } from '../../../common/api/radioAdGroups/radioAdGroups'

import styles from './RadioAdGroup.module.scss'

const schema = yup.object().shape({
  playlistId: yup.string().required('Playlist is required'),
  initialAdBlock: yup.object().shape({
    numberOfTracksBeforeAdBlock: yup
      .number()
      .typeError('Must be a number')
      .min(0, 'Field value must be greater than or equal to 0')
      .required('Field is required'),
    adCountDuringAdBlock: yup
      .number()
      .typeError('Must be a number')
      .min(0, 'Field value must be greater than or equal to 0')
      .required('Field is required'),
  }),
  repeatableAdBlockOne: yup.object().shape({
    numberOfTracksBeforeAdBlock: yup
      .number()
      .typeError('Must be a number')
      .min(0, 'Field value must be greater than or equal to 0')
      .required('Field is required')
      .test(
        '',
        `Set value greater than 0 in at 
        least one repeatable ad block.`,
        (value, schema) => {
          if (typeof value === 'undefined') {
            return true
          }

          const options: any = schema.options

          const repeatableAdBlockTwoNumberOfTracks =
            options.from[1].value.repeatableAdBlockTwo.numberOfTracksBeforeAdBlock

          if (value === 0 && repeatableAdBlockTwoNumberOfTracks === 0) {
            return false
          }

          return true
        },
      ),
    adCountDuringAdBlock: yup
      .number()
      .typeError('Must be a number')
      .min(0, 'Field value must be greater than or equal to 0')
      .required('Field is required'),
  }),
  repeatableAdBlockTwo: yup.object().shape({
    numberOfTracksBeforeAdBlock: yup
      .number()
      .typeError('Must be a number')
      .min(0, 'Field value must be greater than or equal to 0')
      .required('Field is required')
      .test(
        '',
        `Set value greater than 0 in at 
      least one repeatable ad block.`,
        (value, schema) => {
          if (typeof value === 'undefined') {
            return true
          }

          const options: any = schema.options

          const repeatableAdBlockTwoNumberOfTracks =
            options.from[1].value.repeatableAdBlockOne.numberOfTracksBeforeAdBlock

          if (value === 0 && repeatableAdBlockTwoNumberOfTracks === 0) {
            return false
          }

          return true
        },
      ),
    adCountDuringAdBlock: yup
      .number()
      .typeError('Must be a number')
      .min(0, 'Field value must be greater than or equal to 0')
      .required('Field is required'),
  }),
})

interface ParamProps {
  playlistId?: string
}

interface AdGroup {
  playlistId: string
  initialAdBlock: AdBlockData
  repeatableAdBlockOne: AdBlockData
  repeatableAdBlockTwo: AdBlockData
}

const RadioAdGroup = () => {
  const { notificationsStore } = useStores()
  const { playlistId = '' } = useParams<ParamProps>()
  const [currentAddId, setCurrentAddId] = useState('')
  const { data: adGroup, refetch: adGroupRefetch } = useAdGroupQuery(playlistId)

  const [initialValues, setInitialValues] = useState({
    playlistId: '',
    initialAdBlock: {
      numberOfTracksBeforeAdBlock: '',
      adCountDuringAdBlock: '',
    },
    repeatableAdBlockOne: {
      numberOfTracksBeforeAdBlock: '',
      adCountDuringAdBlock: '',
    },
    repeatableAdBlockTwo: {
      numberOfTracksBeforeAdBlock: '',
      adCountDuringAdBlock: '',
    },
  })

  const initialRadioAdGroup = playlistId !== '' ? adGroup : null

  const mutationAdGroup = useMutation<any, Error, RadioAdGroupRequest>(
    (body: RadioAdGroupRequest) => {
      return API.playlist.editAdGroup(body)
    },
    {
      onSuccess: () => {
        notificationsStore.successNotification('Ad Group successfully edited')
        adGroupRefetch()
      },
      onError: (error: any) => {
        notificationsStore.errorNotification(error)
      },
    },
  )

  const onDeleteMutation = useMutation(
    (body: any) => {
      return API.radioAds.deleteAd({ adId: body.id })
    },
    {
      onSuccess: () => {
        notificationsStore.successNotification('Ad successfully deleted')
        adGroupRefetch()
      },
      onError: (error: any) => {
        notificationsStore.errorNotification(error)
      },
    },
  )

  useEffect(() => {
    if (!R.isNil(initialRadioAdGroup)) {
      setInitialValues(adGroup)
    }
  }, [adGroup])

  const handleSubmit = async (values: AdGroup) => {
    const newValues = {
      playlistId: values.playlistId,
      initialAdBlock: values.initialAdBlock,
      repeatableAdBlockOne: values.repeatableAdBlockOne,
      repeatableAdBlockTwo: values.repeatableAdBlockTwo,
    }

    mutationAdGroup.mutate(newValues)
  }

  const formik = useFormik<AdGroup>({
    initialValues: initialValues,
    validationSchema: schema,
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  })

  const { values } = formik

  const handleEditClick = (id: string) => {
    setCurrentAddId(id)
  }

  const handleDeleteClick = (id: string) => {
    onDeleteMutation.mutate({ id: id })
  }

  const refetchAdForm = () => {
    setCurrentAddId('')
    adGroupRefetch()
  }

  return (
    <React.Fragment>
      {playlistId !== '' && R.isNil(initialRadioAdGroup) ? (
        <Spinner />
      ) : (
        <Box>
          <div className={styles.container}>
            <div className={styles.form}>
              <p className={styles.sectionHeader}>{Text.radioAdGroups.adGroupForm.adCadence}</p>
              <div className={styles.ruleRow}>
                <p className={styles.sectionName}>{Text.radioAdGroups.adGroupForm.firstAdBreak}</p>
                <div className={styles.customTextFieldWrapper}>
                  <TextField
                    data-test="number-of-tracks-field"
                    type="number"
                    className={styles.customTextField}
                    label={Text.radioAdGroups.adGroupForm.numberOfTracks + ' *'}
                    name="initialAdBlock.numberOfTracksBeforeAdBlock"
                    value={values.initialAdBlock.numberOfTracksBeforeAdBlock}
                    error={formik.errors.initialAdBlock?.numberOfTracksBeforeAdBlock}
                    InputProps={{
                      inputProps: {
                        min: 0,
                      },
                    }}
                    onChange={formik.handleChange}
                  />
                  <TextField
                    data-test="number-of-ads-field"
                    type="number"
                    className={styles.customTextField}
                    label={Text.radioAdGroups.adGroupForm.numberOfAds + ' *'}
                    name="initialAdBlock.adCountDuringAdBlock"
                    value={values.initialAdBlock.adCountDuringAdBlock}
                    error={formik.errors.initialAdBlock?.adCountDuringAdBlock}
                    InputProps={{
                      inputProps: {
                        min: 0,
                      },
                    }}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
              <div className={styles.ruleRow}>
                <p className={styles.sectionName}>{Text.radioAdGroups.adGroupForm.repeatableAdBreakOne}</p>
                <div className={styles.customTextFieldWrapper}>
                  <TextField
                    data-test="number-of-tracks-field"
                    type="number"
                    className={styles.customTextField}
                    label={Text.radioAdGroups.adGroupForm.numberOfTracks + ' *'}
                    name="repeatableAdBlockOne.numberOfTracksBeforeAdBlock"
                    value={values.repeatableAdBlockOne.numberOfTracksBeforeAdBlock}
                    error={formik.errors.repeatableAdBlockOne?.numberOfTracksBeforeAdBlock}
                    InputProps={{
                      inputProps: {
                        min: 0,
                      },
                    }}
                    onChange={formik.handleChange}
                  />
                  <TextField
                    data-test="number-of-ads-field"
                    type="number"
                    className={styles.customTextField}
                    label={Text.radioAdGroups.adGroupForm.numberOfAds + ' *'}
                    name="repeatableAdBlockOne.adCountDuringAdBlock"
                    value={values.repeatableAdBlockOne.adCountDuringAdBlock}
                    error={formik.errors.repeatableAdBlockOne?.adCountDuringAdBlock}
                    InputProps={{
                      inputProps: {
                        min: 0,
                      },
                    }}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
              <div className={styles.ruleRow}>
                <p className={styles.sectionName}>{Text.radioAdGroups.adGroupForm.repeatableAdBreakTwo}</p>
                <div className={styles.customTextFieldWrapper}>
                  <TextField
                    data-test="number-of-tracks-field"
                    type="number"
                    className={styles.customTextField}
                    label={Text.radioAdGroups.adGroupForm.numberOfTracks + ' *'}
                    name="repeatableAdBlockTwo.numberOfTracksBeforeAdBlock"
                    value={values.repeatableAdBlockTwo.numberOfTracksBeforeAdBlock}
                    error={formik.errors.repeatableAdBlockTwo?.numberOfTracksBeforeAdBlock}
                    InputProps={{
                      inputProps: {
                        min: 0,
                      },
                    }}
                    onChange={formik.handleChange}
                  />
                  <TextField
                    data-test="number-of-ads-field"
                    type="number"
                    className={styles.customTextField}
                    label={Text.radioAdGroups.adGroupForm.numberOfAds + ' *'}
                    name="repeatableAdBlockTwo.adCountDuringAdBlock"
                    value={values.repeatableAdBlockTwo.adCountDuringAdBlock}
                    error={formik.errors.repeatableAdBlockTwo?.adCountDuringAdBlock}
                    InputProps={{
                      inputProps: {
                        min: 0,
                      },
                    }}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
              <div className={styles.submitContainer}>
                <Button data-test="submit-button" onClick={formik.handleSubmit}>
                  {Text.radioAdGroups.adGroupForm.saveCadence}
                </Button>
              </div>
              <div className={styles.adsWrapper}>
                <div className={styles.adColumn}>
                  <p className={styles.sectionHeader}>{Text.radioAdGroups.adGroupForm.adList}</p>
                  {adGroup?.ads.length !== 0 && !R.isNil(initialRadioAdGroup) ? (
                    adGroup?.ads.map((ad: Ad) => {
                      return (
                        <AdItemWithMoreInfo
                          key={ad.basicInfo.id}
                          adGroupId={adGroup.id}
                          ad={ad}
                          refetch={adGroupRefetch}
                          onEdit={handleEditClick}
                          onDelete={handleDeleteClick}
                        />
                      )
                    })
                  ) : (
                    <p className={styles.noSelectedStyng}>There are no ads in group</p>
                  )}
                </div>
                <RadioAd
                  id={currentAddId}
                  playlistId={playlistId}
                  adGroup={initialRadioAdGroup}
                  refetchAdForm={refetchAdForm}
                />
              </div>
            </div>
          </div>
        </Box>
      )}
    </React.Fragment>
  )
}

export default observer(RadioAdGroup)
