import { action, observable } from 'mobx'
import { useQuery } from 'react-query'
import * as R from 'ramda'

import { StynglistsResponse, EditStynglistEntity } from '../common/api/stynglist/stynglist'
import useStores from '../common/hook/useStore'
import PaginationStore from '../common/PaginationStore'
import API from '../common/api'

class StynglistsStore extends PaginationStore {
  @observable
  id: string = ''
  @observable
  name: string = ''
  @observable
  appName: string = ''
  @observable
  genreIds: string = ''
  @observable
  published: boolean | null = null

  @action
  handleIdSearch = (filter: string) => {
    this.id = filter?.trim()
    this.changePage()
  }

  @action
  handleNameSearch = (filter: string) => {
    this.name = filter?.trim()
    this.changePage()
  }

  @action
  handleAppSearch = (filter: string) => {
    this.appName = filter?.trim()
    this.changePage()
  }

  @action
  handleGenreSearch = (filter: []) => {
    this.genreIds = filter.toString()
    this.changePage()
  }

  @action
  handlePublishSearch = (filter: boolean | null) => {
    this.published = filter
    this.changePage()
  }
}

export const useStynglistsQuery = (filterStore: StynglistsStore) => {
  const { pageSize, page, id, name, appName, genreIds, published } = filterStore

  return useQuery<StynglistsResponse>(
    ['stynglists', pageSize, page, id, name, appName, genreIds, published],
    () => {
      return API.stynglist.list({
        page,
        size: pageSize,
        ids: id,
        name,
        appName,
        genreIds,
        isActive: published,
      })
    },
    {
      retry: 1,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  )
}

export const useStynglistsAppQuery = (appId: Nullable<string> = null) => {
  const { stynglistsStore } = useStores()

  const { pageSize, page } = stynglistsStore

  return useQuery<StynglistsResponse>(
    ['stynglistsApp', appId],
    () => {
      return API.stynglist.list({
        page,
        size: pageSize,
        name: '',
        appId,
      })
    },
    {
      retry: 1,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  )
}

export const useStynglistQuery = (id: string) => {
  return useQuery<EditStynglistEntity>(
    ['stynglist', id],
    () => {
      return (
        API.stynglist.get({
          id,
        }) ?? []
      )
    },
    {
      refetchOnWindowFocus: false,
      enabled: !R.isEmpty(id),
      keepPreviousData: false,
      retry: 1,
    },
  )
}

export default StynglistsStore
