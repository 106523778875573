export const usePredefinedClassIfFound = (passedClassNames = '', styles: { [className: string]: string }) =>
  passedClassNames
    ? passedClassNames
      .split(' ')
      .map((className) => replaceClassIfFoundInStyles(className, styles))
      .join(' ')
    : ''

export const replaceClassIfFoundInStyles = (className: string, styles: { [className: string]: string }) => {
  const styleDefined = Object.keys(styles).find((key) => key === className)

  return styleDefined ? styles[className] : className
}
