import strings from '../locale/strings'
import Route from 'lib/routing/Route'

import Reports from './pages/Reports'

export const ReportsRoute: Route = {
  path: '/reporting',
  name: strings.navigation.reports,
  exact: true,
  component: Reports,
}
