import React from 'react'
import MuiRadio from '@mui/material/Radio'

interface RadioProps {
  name: string
  value: string
  onChange: (value: string) => void
  checked?: boolean
  className?: string
  disabled?: boolean
}

const Radio: React.FC<RadioProps> = ({
  value,
  checked,
  disabled = false,
  name,
  onChange,
  className = '',
}: RadioProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value)
  }

  return <MuiRadio checked={checked} value={value} name={name} disabled={disabled} onChange={handleChange} />
}

export default Radio
