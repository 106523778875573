// import React from 'react'
import Modal from '@mui/material/Modal'
import Button, { ButtonColor, ButtonVariant } from '../Button/Button'
import Text from '../../locale/strings'

import styles from './confirmationModal.module.scss'

interface ConfirmationModalProps {
  open: boolean
  text: string
  headerContent: string
  handleClose: () => void
  handleSubmit: () => void
}

const ConfirmationModal = ({ open, text, headerContent, handleClose, handleSubmit }: ConfirmationModalProps) => {
  return (
    <div className={styles.holder}>
      <Modal open={open}>
        <div className={styles.container}>
          <div className={styles.header}>{headerContent}</div>
          <div className={styles.text}>{text}</div>
          <div className={styles.submitContainer}>
            <Button data-test="cancel-button" variant={ButtonVariant.OUTLINED} onClick={handleClose}>
              {Text.common.cancel}
            </Button>
            <Button data-test="submit-button" color={ButtonColor.PRIMARY} onClick={handleSubmit}>
              {Text.common.save}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default ConfirmationModal
