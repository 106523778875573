import { StyledEngineProvider } from '@mui/material/styles'
import 'babel-plugin-jsx-control-statements'
import React from 'react'
import ReactDOM from 'react-dom'
import Root from 'Root'

ReactDOM.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <Root />
    </StyledEngineProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)
