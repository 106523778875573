import React from 'react'

import { Styng } from 'common/api/styng/styng'
import styles from './styngResult.module.scss'

interface StyngResultProps {
  styng: Styng
  onClick: (track: Styng) => void
}

const StyngResult = ({ styng, onClick }: StyngResultProps) => {
  const handleClick = () => {
    onClick(styng)
  }

  return (
    <div className={styles.container} onClick={handleClick}>
      <h4>{styng.name}</h4>
    </div>
  )
}

export default StyngResult
